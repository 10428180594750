import { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { getBasketCount } from "../redux/basketSlice";
import CheckoutOverview from "../components/checkout/CheckoutOverview";
import CheckoutEmpty from "../components/checkout/CheckoutEmpty";
import CheckoutPersonalInfo from "../components/checkout/CheckoutPersonalInfo";
import PleaseWait from "../views/PleaseWait";
import { Link, useParams } from "react-router-dom";

const MenyvelgerenCheckout = () => {
  const storeKey = useParams().store;
  const [masterLoader, setMasterLoader] = useState(false);
  const basketCount = useSelector(getBasketCount);

  if (masterLoader) {
    return <PleaseWait />;
  } else if (basketCount === 0) {
    return <CheckoutEmpty />;
  } else {
    return (
      <>
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
              },
            }}
          >
            <Button
              component={Link}
              variant="contained"
              to={`/${storeKey}`}
              color="secondary"
              sx={{ display: "block", textAlign: "center", marginTop: "25px" }}
            >
              Handle mer &raquo;
            </Button>
            <CheckoutOverview />
          </Grid>
          <Grid item xs={12} md={8}>
            <CheckoutPersonalInfo setMasterLoader={setMasterLoader} />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              },
            }}
          >
            <Button
              component={Link}
              variant="contained"
              to={`/${storeKey}`}
              color="secondary"
              sx={{ display: "block", textAlign: "center", marginTop: "25px" }}
            >
              Handle mer &raquo;
            </Button>
            <CheckoutOverview />
          </Grid>
        </Grid>
      </>
    );
  }
};

export default MenyvelgerenCheckout;
