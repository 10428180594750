import { Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import StoreOpeningHours from "../components/common/StoreOpeningHours";
import { selectStoreData } from "../redux/storeDataSlice";

const StoreClosed = ({ showMenuHeader }) => {
  const storeData = useSelector(selectStoreData);
  const { openinghours, orderopeninghours } = storeData || {};

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "50px 25px", padding: "15px" }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            Stasjonen kan dessvere ikke da i mot bestillinger akkurat nå.
          </Typography>

          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={{ margin: "25px 0px", height: "50px" }}>
                Av kapasitetshensyn kan vi kun ta i mot digitale bestillinger i
                tidsrommene nedenfor.
              </Typography>
              {orderopeninghours && (
                <StoreOpeningHours
                  title="Bestillinger"
                  openingHours={orderopeninghours}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{
                  margin: {
                    xs: "55px 0px 25px 0px",
                    sm: "55px 0px 25px 0px",
                    md: "25px 0px",
                  },
                  height: "50px",
                }}
              >
                Kom gjerne innom stasjonen i våre åpningstider
              </Typography>
              {openinghours && (
                <StoreOpeningHours
                  title="Stasjonens åpningstider"
                  openingHours={openinghours}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showMenuHeader && (
        <>
          <Divider />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "50px 25px", padding: "15px" }}
          >
            <Grid item xs={12}>
              <Typography variant="h5">
                Ta gjerne en titt i menyen vår nedenfor.
              </Typography>
              <Typography variant="h6">
                Bestillinger kan gjøres i stasjonens åpningstid
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );

  // showMenuHeader
};

export default StoreClosed;
