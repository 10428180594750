import styled from "styled-components";
import { Grid, ListItem, ListItemText } from "@mui/material";
import { FORMATTER } from "../../services";
import { StyledListNoPad, StyledProductTitle } from "./CheckoutStyles";
import { useSelector } from "react-redux";
import { getEatIn } from "../../redux/basketSlice";
import { selectStoreHasInPrice } from "../../redux/storeDataSlice";

const StyledProductPrice = styled.div`
  font-size: 20x;
  display: block;
  text-align: right;
  padding-top: 10px;
`;

const CheckoutOverviewProduct = ({ item }) => {
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const { product, addons, qty, price_in, price_out, visibleComment, comment } =
    item || {};

  const renderAddons = () => {
    return (addons || [])
      .map((addon) => {
        if (addon.option === addon.title) {
          return `- ${addon.title}`;
        } else {
          return `- ${addon.title} - ${addon.option}`;
        }
      })
      .map((item, idx) => (
        <ListItem key={`addons_item_${idx}`}>
          <ListItemText primary={item} />
        </ListItem>
      ));
  };

  if (!product) return <></>;

  return (
    <Grid container sx={{ padding: "10px 0px" }}>
      <Grid item xs={12} sx={{ padding: "5px" }}>
        <StyledProductTitle>{product.title}</StyledProductTitle>

        <StyledListNoPad>
          {qty > 1 && (
            <ListItem className="qty-line">
              <ListItemText primary={`- Antall: ${qty}`} />
            </ListItem>
          )}
          {renderAddons()}
          {visibleComment && (
            <ListItem className="qty-line">
              <ListItemText primary={`- Kommentar: ${comment}`} />
            </ListItem>
          )}
        </StyledListNoPad>

        <StyledProductPrice>
          {FORMATTER.formatCurrent(eatIn ? price_in : price_out)}
        </StyledProductPrice>
      </Grid>
    </Grid>
  );
};

export default CheckoutOverviewProduct;
