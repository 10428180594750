import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { COLORS } from "../../../constants";

export const StyledHeader = styled("div")`
  border-top: 5px solid ${COLORS.SECONDARY};

  .header-main {
    height: 80px;
    padding: 5px 0px 5px 0px;

    @media only screen and (max-width: 400px) {
      padding: 5px 0px 25px 0px;
    }
  }

  .menu-wrapper {
    background: ${COLORS.PRIMARY};
  }

  .header-main-wrapper {
    background: ${COLORS.PRIMARY};
    height: 90px;

    @media only screen and (max-width: 400px) {
      height: 125px;
    }

    .logo-wrapper {
      width: 65px;
      height: calc(105px - 20px);
      padding: 0px 25px 0px 10px;
      position: relative;
      z-index: 99;
      float: left;
      img {
        width: 100%;
        margin-top: 10px;
      }

      @media only screen and (max-width: 400px) {
        width: 45px;
        padding: 20px 10px 20px 0px;
        height: calc(105px - 45px);
        margin-left: -10px;
      }
    }

    .store-title-one {
      margin: 0;
      padding: 10px 0px 0px 0px;
      font-weight: bold;
      padding-left: 15px;
      font-size: 22px;
      font-style: italic;
      color: white;
      line-height: 35px;

      @media only screen and (max-width: 400px) {
        font-size: 18px;
      }
    }

    .store-title-2,
    .store-title-3 {
      padding: 0;
      margin: 0;
      font-weight: normal;
      padding-left: 15px;
      font-size: 14px;
      font-style: italic;
      color: white;
      line-height: 25px;

      @media only screen and (max-width: 400px) {
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
`;

export const StyledMenuButton = styled(Button)`
  margin: 7.5px 5px;
  height: 45px;
  line-height: 30px;
`;

export const StyledMenuIconButton = styled(Button)`
  margin: 7.5px 5px 7.5px 5px;
  height: 45px;
  padding: 0;

  .MuiBadge-badge {
    margin-top: -5px;
  }

  @media only screen and (max-width: 899px) {
    margin: 0px 5px;
  }
`;
