import {
  Box,
  Badge,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { useSelector } from "react-redux";
import { COLORS, ORDERWEB_MODULES } from "../../../constants";
import { getBasketCount } from "../../../redux/basketSlice";
import { Link, useParams } from "react-router-dom";
import { StyledMenuIconButton, StyledMenuButton } from "./HeaderStyles";
import MenuIcon from "@mui/icons-material/Menu";
import UserAccountButton from "./UserAccountButton";
import { useState } from "react";

const HeaderMenuDrawer = ({
  drawerOpen,
  setDrawerOpen,
  menuStation,
  menuGeneric,
}) => {
  const storeKey = useParams().store;
  const menuPages = storeKey ? menuStation : menuGeneric;

  return (
    <SwipeableDrawer
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      onOpen={() => setDrawerOpen(true)}
    >
      <Box
        role="presentation"
        sx={{ width: 250 }}
        onClick={() => setDrawerOpen(false)}
        onKeyDown={() => setDrawerOpen(false)}
      >
        <List>
          {menuPages.map(({ title, page }, idx) => (
            <ListItem
              button
              key={`main_menu_${idx}`}
              to={page(storeKey)}
              component={Link}
            >
              <ListItemText>{title}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

const HeaderMenu = ({ color, menuStation, menuGeneric, height }) => {
  const storeKey = useParams().store;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const basketCount = useSelector(getBasketCount);
  const menuPages = storeKey ? menuStation : menuGeneric;

  const renderMainMenu = () =>
    menuPages.map(({ title, page }, idx) => (
      <StyledMenuButton
        component={Link}
        key={`main_menu_${idx}`}
        to={page(storeKey)}
        sx={{ my: 2, color: color, display: "block" }}
        size="large"
      >
        {title}
      </StyledMenuButton>
    ));

  return (
    <>
      <HeaderMenuDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        storeKey={storeKey}
        menuStation={menuStation}
        menuGeneric={menuGeneric}
      />
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
          {renderMainMenu()}
        </Box>
        <StyledMenuIconButton
          size="large"
          title="Navigasjon"
          onClick={() => setDrawerOpen(true)}
          sx={{
            color: color === "primary" ? COLORS.PRIMARY : color,
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              "&.Mui-disabled": {
                color: "rgba(127,127,127,1)",
              },
            },
            lineHeight: "45px",
            textAlign: "center",
          }}
        >
          <Badge badgeContent={0} color="secondary">
            <MenuIcon />
          </Badge>
        </StyledMenuIconButton>

        <div style={{ flex: "1 0 0" }} />
        <UserAccountButton color={color} />
        <StyledMenuIconButton
          size="large"
          title={storeKey ? "Handlekurv" : "Velg stasjon først"}
          sx={{
            my: 2,
            color: color === "primary" ? COLORS.PRIMARY : color,
            display: "block",
            lineHeight: "45px",
            textAlign: "center",
            "&.Mui-disabled": {
              color: "rgba(127,127,127,1)",
            },
          }}
          component={Link}
          disabled={!storeKey}
          to={storeKey ? `/${storeKey}/${ORDERWEB_MODULES.CHECKOUT}` : ""}
        >
          <Badge badgeContent={storeKey ? basketCount : 0} color="secondary">
            <ShoppingCartCheckoutIcon />
          </Badge>
        </StyledMenuIconButton>
      </Box>
    </>
  );
};

export default HeaderMenu;
