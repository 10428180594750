import { Container, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import OrderWebFrame from "../components/common/OrderWebFrame";
import { useSelector } from "react-redux";
import { selectActiveUserData } from "../redux/activeSessionSlice";
import LoginForm from "../components/common/LoginForm";
import MyInfo from "../components/myprofile/MyInfo";
import MyBonus from "../components/myprofile/MyBonus";
import MyOrders from "../components/myprofile/MyOrders";
import { Link, useParams } from "react-router-dom";
import { createProfileLink, PROFILE_MODULES } from "../constants";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const MyProfile = () => {
  const activeUser = useSelector(selectActiveUserData);
  const storeKey = useParams().store;
  const activeModule = useParams().module;

  const renderBody = () => {
    switch (activeModule) {
      default:
      case PROFILE_MODULES.BONUS:
        return <MyBonus />;
      case PROFILE_MODULES.ORDERS:
        return <MyOrders />;
      case PROFILE_MODULES.PROFILE:
        return <MyInfo />;
    }
  };

  const getTabIndex = () => {
    switch (activeModule) {
      default:
      case PROFILE_MODULES.BONUS:
        return 0;
      case PROFILE_MODULES.ORDERS:
        return 1;
      case PROFILE_MODULES.PROFILE:
        return 2;
    }
  };

  if (activeUser) {
    return (
      <OrderWebFrame>
        <Box
          sx={{
            margin: "25px 0px",
            width: "100%",
          }}
        >
          <Tabs
            value={getTabIndex()}
            aria-label="basic tabs example"
            variant="scrollable"
            sx={{
              [`& .MuiTabs-scrollButtons`]: {
                "&.Mui-disabled": {
                  opacity: 0.3,
                },
                "&": {
                  display: {
                    xs: "flex",
                    sm: "none",
                  },
                },
              },
            }}
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              component={Link}
              to={createProfileLink(storeKey, PROFILE_MODULES.BONUS)}
              label="Din bonus"
              {...a11yProps(0)}
            />
            <Tab
              component={Link}
              to={createProfileLink(storeKey, PROFILE_MODULES.ORDERS)}
              label="Dine bestillinger"
              {...a11yProps(2)}
            />
            <Tab
              component={Link}
              to={createProfileLink(storeKey, PROFILE_MODULES.PROFILE)}
              label="Din profil"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        {renderBody()}
      </OrderWebFrame>
    );
  } else {
    return (
      <OrderWebFrame>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LoginForm />
          </Box>
        </Container>
      </OrderWebFrame>
    );
  }
};

export default MyProfile;
