import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import OrderDetails from "../components/common/OrderDetails";
import { selectStoreData } from "../redux/storeDataSlice";

const MenyvelgerenConfirm = () => {
  const [searchParams] = useSearchParams();
  const storeData = useSelector(selectStoreData);
  const orderAccessKey = searchParams.get("a");
  const orderid = searchParams.get("pk");
  const store = storeData && storeData.store ? storeData.store.mid : 0;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ margin: "25px 0px" }}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{
              padding: {
                xs: "5px 0px",
                sm: "15px 15px",
                md: "25px 15px",
              },
            }}
          >
            Din bestilling
          </Typography>

          <Container
            maxWidth="md"
            sx={{
              padding: {
                xs: 0,
                sm: "24px",
              },
            }}
          >
            <Paper elevation={4}>
              <OrderDetails
                store={store}
                orderid={orderid}
                orderAccessKey={orderAccessKey}
              />
            </Paper>
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MenyvelgerenConfirm;
