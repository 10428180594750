import { Grid, List, useMediaQuery, useTheme } from "@mui/material";
import { ItemGridButton, ItemListButton } from "./ItemGridButton";

const ItemGridBody = ({ items, useImage }) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        height: "100%",
      }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={2}
          columnSpacing={2}
        >
          {items.map(
            ({ href, text, icon, image, useImage: itemUseImage }, idx) => (
              <Grid item xs={4} key={`${text}_${idx}`}>
                <ItemGridButton
                  small={isMediumScreen}
                  href={href}
                  text={text}
                  icon={icon}
                  image={image}
                  useImageContain={itemUseImage}
                  useImage={useImage || itemUseImage}
                />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const ItemListBody = ({ items, useImage }) => {
  return (
    <List>
      {items.map(({ href, text, icon, image }, idx) => (
        <ItemListButton
          key={`${text}_${idx}`}
          href={href}
          text={text}
          icon={icon}
          image={image}
          useImage={useImage}
        />
      ))}
    </List>
  );
};

const ItemGrid = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return isSmallScreen ? (
    <ItemListBody {...props} />
  ) : (
    <ItemGridBody {...props} />
  );
};

export default ItemGrid;
