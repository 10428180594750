import React, { useEffect, useState } from "react";
import { SERVICES } from "../../services";
import {
  Paper,
  Typography,
  Avatar,
  LinearProgress,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { selectActiveToken } from "../../redux/activeSessionSlice";
import { COLORS } from "../../constants";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import OrderTable from "./OrderTable";

const MyOrders = () => {
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const activeToken = useSelector(selectActiveToken);

  const loadData = async (offset, reset) => {
    const orders = await SERVICES.fetchUserOrders(activeToken, offset);
    if (orders && orders.orders) {
      const loadedOrdersCount = orders?.orders ? orders.orders.length : 0;
      const oldOrders = reset ? [] : orderData;
      const newOrders = [...oldOrders, ...orders.orders];
      setOrderData(newOrders);
      setLoadMore(loadedOrdersCount > 0);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeToken) loadData(0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeToken]);

  const handleLoadMore = () => {
    const currentDataLength = orderData ? orderData.length : 0;
    setLoading(true);
    loadData(currentDataLength);
  };

  if (activeToken && orderData) {
    return (
      <Paper
        sx={{
          margin: "25px 0px",
          minHeight: "250px",
          padding: "10px",
        }}
        elevation={3}
      >
        <Avatar
          sx={{
            bgcolor: COLORS.PRIMARY,
            float: "left",
            margin: {
              xs: "10px 5px 10px 0px",
              sm: "10px 15px",
            },
            transform: {
              xs: "scale(0.7)",
              sm: "scale(1)",
            },
          }}
        >
          <ShoppingBasketIcon />
        </Avatar>
        <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
          Dine bestillinger
        </Typography>

        <Box
          sx={{
            margin: {
              xs: 0,
              sm: 1,
              md: 2,
            },
          }}
        >
          <OrderTable orders={orderData} />
        </Box>
        {!loading && loadMore ? (
          <Box
            sx={{ textAlign: "center", maxWidth: "700px" }}
            onClick={handleLoadMore}
          >
            <Button>Last inn flere</Button>
          </Box>
        ) : (
          ""
        )}
        {loading && (
          <LinearProgress
            sx={{ maxWidth: "700px", margin: "24px 5px 25px 5px" }}
          />
        )}
      </Paper>
    );
  }
};

export default MyOrders;
