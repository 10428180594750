import OrderWebFrame from "../components/common/OrderWebFrame";
import { ORDERWEB_MODULES } from "../constants";
import { useParams } from "react-router-dom";
import Menyvelgeren from "../modules/Menyvelgeren";
import PleaseWait from "./PleaseWait";
import { useDispatch, useSelector } from "react-redux";
import { selectStoreData } from "../redux/storeDataSlice";
import MenyvelgerenCheckout from "../modules/MenyvelgerenCheckout";
import MenyvelgerenBasket from "../modules/MenyvelgerenBasket";
import ContactUs from "./ContactUs";
import { Step, StepLabel } from "@mui/material";
import MenyvelgerenConfirm from "../modules/MenyvelgerenConfirm";
import StyledStepper from "../components/common/StyledStepper";
import StoreClosed from "./StoreClosed";
import {
  loadMenyvelgeren,
  selectMenyvelgerenLoading,
  selectMenyvelgerenMenus,
} from "../redux/menyvelgerenDataSlice";
import { useEffect } from "react";
import GenericTextContent from "../components/common/GenericTextContent";

const renderStepper = (activeStep) => (
  <StyledStepper activeStep={activeStep}>
    <Step>
      <StepLabel>Finn produkter</StepLabel>
    </Step>
    <Step>
      <StepLabel>Din bestilling</StepLabel>
    </Step>
    <Step>
      <StepLabel>Kvittering</StepLabel>
    </Step>
  </StyledStepper>
);

const OrderWeb = () => {
  const dispatch = useDispatch();
  const currentModule = useParams().module;
  const currentRefId = useParams().refid;
  const storeData = useSelector(selectStoreData);
  const menuData = useSelector(selectMenyvelgerenMenus);
  const menuDataLoading = useSelector(selectMenyvelgerenLoading);
  const { isopen, isopenfororders } = storeData || {};

  useEffect(() => {
    dispatch(
      loadMenyvelgeren(storeData && storeData.store ? storeData.store.mid : 0)
    );
  }, [storeData, dispatch]);

  const renderBody = () => {
    const showLoader = menuDataLoading;

    if (showLoader) {
      return <PleaseWait />;
    } else if (currentModule === ORDERWEB_MODULES.CONFIRM) {
      return <MenyvelgerenConfirm />;
    } else if (currentModule === ORDERWEB_MODULES.BASKET) {
      return (
        <>
          {renderStepper(1)}
          <MenyvelgerenBasket />
        </>
      );
    } else if (currentModule === ORDERWEB_MODULES.CHECKOUT) {
      if (!isopen || !isopenfororders) {
        return <StoreClosed />;
      }
      return (
        <>
          {renderStepper(1)}
          <MenyvelgerenCheckout />
        </>
      );
    } else if (
      currentModule === ORDERWEB_MODULES.MENULIST ||
      currentModule === ORDERWEB_MODULES.PRODUCTLIST
    ) {
      return (
        <>
          {isopen && isopenfororders && renderStepper(0)}
          {(!isopen || !isopenfororders) &&
            currentModule === ORDERWEB_MODULES.MENULIST &&
            currentRefId === undefined && <StoreClosed showMenuHeader={true} />}
          <Menyvelgeren
            menuData={menuData}
            hideAddButton={!(isopen && isopenfororders)}
            filter={{
              type: currentModule,
              refid: currentRefId ? `${currentRefId}`.toUpperCase() : undefined,
            }}
          />
        </>
      );
    } else if (currentModule === ORDERWEB_MODULES.SALESTERMS) {
      return <GenericTextContent text="salesterms" />;
    } else if (currentModule === ORDERWEB_MODULES.DELETEME) {
      return <GenericTextContent text="deleteme" />;
    } else if (currentModule === ORDERWEB_MODULES.ABOUTPROGRAM) {
      return <GenericTextContent text="aboutbonus" />;
    } else if (currentModule === ORDERWEB_MODULES.CONTACT_US) {
      return <ContactUs />;
    } else {
      return (
        <>
          {isopen && isopenfororders && renderStepper(0)}
          {(!isopen || !isopenfororders) && (
            <StoreClosed showMenuHeader={true} />
          )}
          <Menyvelgeren
            menuData={menuData}
            hideAddButton={!(isopen && isopenfororders)}
          />
        </>
      );
    }
  };

  return (
    <OrderWebFrame hideCartCounter={!(isopen && isopenfororders)}>
      {renderBody()}
    </OrderWebFrame>
  );
};

export default OrderWeb;
