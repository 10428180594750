import { styled } from "@mui/system";
import { Stepper } from "@mui/material";
import { COLORS } from "../../constants";

const StyledStepper = styled(Stepper)(
  ({ theme, activeStep, totalnumberofsteps }) => `
  padding: 25px 25px;
  .MuiSvgIcon-root {
    ${
      activeStep > totalnumberofsteps - 1
        ? `color: ${theme.palette.success.main};`
        : ``
    }
  }

  .MuiStepLabel-labelContainer span {
    ${
      activeStep > totalnumberofsteps - 1
        ? `color: ${theme.palette.success.main};`
        : ``
    }
  }

  .MuiSvgIcon-root.Mui-active {
    ${
      activeStep > totalnumberofsteps - 1
        ? `color: ${theme.palette.success.main};`
        : `color: ${COLORS.SECONDARY};`
    }
  }

  @media only screen and (max-width: 600px) {
    .MuiStepLabel-labelContainer {
      display: none;
      background: red;
    }
  }
`
);
export default StyledStepper;
