import moment from "moment";
import { ENDPOINTS, MENYVELGER_MENUGROUP } from "./constants";

const makePostRequest = (url, data) => {
  const formData = new FormData();
  for (var k in data) {
    if (typeof data[k] !== "undefined") formData.append(k, data[k]);
  }
  return fetch(url, {
    method: "POST",
    cache: "no-cache",
    body: formData,
  });
};

export const SERVICES = {
  fetchStores: () => fetch(`${ENDPOINTS.base()}web/stores`),
  fetchStoreData: (storeKey) =>
    fetch(`${ENDPOINTS.base()}web/store?key=${storeKey}`),
  fetchDeliveryTime: (store) =>
    fetch(`${ENDPOINTS.base()}menyvelger/deliverytime?store=${store}`),
  fetchMenuAndProductData: (store) =>
    fetch(`${ENDPOINTS.base()}menyvelger/products?store=${store}`),
  fetchAddonsData: (store, pid) =>
    fetch(`${ENDPOINTS.base()}menyvelger/addons?store=${store}&pid=${pid}`),
  fetchOrderData: (store, orderid, accesskey, token) =>
    makePostRequest(`${ENDPOINTS.base()}web/order`, {
      store: store,
      orderid: orderid,
      accesskey: accesskey,
      token: token,
    }),
  forgotPassword: async (phone) => {
    const r = await makePostRequest(`${ENDPOINTS.base()}session/newpassword`, {
      username: phone,
    });
    if (r && r.ok) return await r.json();
    return "ERROR";
  },
  placeOrder: async (store, token, bonus, basket, customerFormData) => {
    const {
      fullName,
      phoneNumber,
      eatin,
      paymentprovider,
      istimedelivery,
      deliveryday,
      deliveryhour,
      deliveryminute,
      ordercomment,
    } = customerFormData;

    const newbasket = basket.map(function (o) {
      const newObj = JSON.parse(JSON.stringify(o));
      if (o && o.product && o.product.accounting) {
        newObj.product.accounting = JSON.parse(o.product.accounting);
      }
      return newObj;
    });

    const r = await makePostRequest(`${ENDPOINTS.base()}web/placeorder`, {
      token: token,
      fullName: fullName,
      phoneNumber: phoneNumber,
      ordercomment: ordercomment,
      bonus: bonus,
      store: store,
      useragent: navigator ? navigator.userAgent : "",
      eatin: eatin,
      payment: paymentprovider,
      deliveryType: istimedelivery === "true" ? "spesific" : "now",
      deliveryTime: moment(
        deliveryday +
          " " +
          ("000" + deliveryhour).slice(-2) +
          ":" +
          ("000" + deliveryminute).slice(-2)
      ).format(),
      order: JSON.stringify(newbasket),
    });

    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  cancelOrder: (store, orderid, accesskey, token) =>
    makePostRequest(`${ENDPOINTS.base()}web/cancelorder`, {
      store: store,
      orderid: orderid,
      accesskey: accesskey,
      token: token,
    }),
  fetchPaymentProviders: async (store) => {
    const r = await fetch(
      `${ENDPOINTS.paymentBase()}api/v1/paymentproviders/${store}`
    );
    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  fetchUserProfile: async (token) => {
    const r = await makePostRequest(`${ENDPOINTS.base()}session/current`, {
      token: token,
    });

    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  deleteMyProfile: async (token) => {
    const r = await makePostRequest(
      `${ENDPOINTS.base()}session/deleteprofile`,
      {
        token: token,
      }
    );

    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  signOutSession: async (token) => {
    const r = await makePostRequest(`${ENDPOINTS.base()}session/logout`, {
      token: token,
    });

    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  fetchUserBonus: async (token) => {
    const r = await makePostRequest(`${ENDPOINTS.base()}session/userbonus`, {
      token: token,
    });

    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  fetchUserOrders: async (token, offset) => {
    const r = await makePostRequest(`${ENDPOINTS.base()}web/orderhistory`, {
      token: token,
      offset: offset,
    });

    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  saveUserProfile: async (token, saveProfile) => {
    const r = await makePostRequest(`${ENDPOINTS.base()}session/saveprofile`, {
      token: token,
      ...saveProfile,
    });
    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  signInUser: async (username, password, remember) => {
    const r = await makePostRequest(`${ENDPOINTS.base()}session/login`, {
      username: username,
      password: password,
    });
    if (r && r.ok) return await r.json();
    return { status: "ERROR" };
  },
  getGenericText: async (text, storeid) => {
    const r = await fetch(`${ENDPOINTS.base()}text/${text}/${storeid}`);
    if (r && r.ok) return await r.json();
    return false;
  },
};

export const FORMATTER = {
  parseNumber: (number) => {
    if (typeof number === "number") {
      return number;
    } else if (typeof number === "string") {
      const newNumber = number
        .replace(/,-/, "")
        .replace(/ /g, "")
        .replace(/,/g, ".");
      const parsed = parseFloat(newNumber);
      if (isNaN(parsed)) return 0;
      return parsed;
    }
    return 0;
  },
  formatDateTime: (time) => moment(time).format("DD.MM.YYYY HH:mm:ss"),
  formatDate: (time) => moment(time).format("DD.MM.YYYY"),
  formatCurrent: (number, qty, includeDecimal) => {
    let n;
    const parsedQty = parseInt(qty) ? parseInt(qty) : 1;
    if (typeof number === "number") {
      n = number;
    } else if (typeof number === "string") {
      n = FORMATTER.parseNumber(number);
    } else if (typeof number === "object" && number && number.length) {
      n = number
        .map((e) => FORMATTER.parseNumber(e))
        .reduce((sum, a) => sum + a, 0);
    } else {
      return number;
    }

    if (isNaN(n)) return number;
    const formattedNumber = (n * parsedQty).toLocaleString("nb-NO", {
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    });

    return includeDecimal ? formattedNumber : `${formattedNumber},-`;
  },
};

export const DISTANCE_TOOLBOX = {
  between: (lat1, lon1, lat2, lon2) => {
    var R = 6378.137; // Radius of earth in KM
    var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 1000; // meters
  },
  pretty: (distance) => {
    // distance is in meters
    if (distance < 950) {
      return Math.round(distance) + " meter";
    } else if (distance < 10000) {
      return Math.round(distance / 100) / 10 + " km";
    } else {
      return Math.round(distance / 1000) + " km";
    }
  },
};

export const getMenuGroupById = (group) =>
  group && MENYVELGER_MENUGROUP[group]
    ? MENYVELGER_MENUGROUP[group]
    : MENYVELGER_MENUGROUP.OTHER;
