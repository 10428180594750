import { CircularProgress, Grid } from "@mui/material";

const PleaseWait = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Grid container direction="row">
          <CircularProgress
            size={100}
            thickness={2}
            style={{ marginTop: "50px", marginBottom: "50px" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PleaseWait;
