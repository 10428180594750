import { configureStore } from "@reduxjs/toolkit";
import activeSessionSlice from "./activeSessionSlice";
import basketSlice from "./basketSlice";
import storeDataReducer from "./storeDataSlice";
import menyvelgerenDataReducer from "./menyvelgerenDataSlice";
import menuPositionSlice from "./menuPositionSlice";

const LOCALSTORE_KEY = "food4u-basket-v1";
const TOKEN_KEY = "food4u-access-token";

const isValidBasket = (basketData) => {
  try {
    if (basketData && basketData.basket) {
      for (var storekey in basketData.basket) {
        if (basketData.basket[storekey] && basketData.basket[storekey].length) {
          const errorProducts = basketData.basket[storekey].filter(
            (item) =>
              !(item.qty > 0) || !(item.price_in >= 0) || !(item.price_out >= 0)
          );
          if (errorProducts && errorProducts.length) return false;
        }
      }
    }
  } catch (e) {
    return false;
  }
  return true;
};

const getStoredSession = () => {
  const availableStorage = [
    {
      storage: sessionStorage,
      remember: false,
    },
    {
      storage: localStorage,
      remember: true,
    },
  ];
  for (let idx in availableStorage) {
    const { storage, remember } = availableStorage[idx];
    const token = storage.getItem(TOKEN_KEY);
    if (token) {
      return {
        token: token,
        rememberToken: remember,
      };
    }
  }

  return undefined;
};

const loadState = () => {
  try {
    let validBasket = false;
    let basketData = false;
    const serializedState = localStorage.getItem(LOCALSTORE_KEY);
    if (serializedState) {
      basketData = JSON.parse(serializedState);
      validBasket = basketData && isValidBasket(basketData);
    }
    return {
      basketData: validBasket ? basketData : undefined,
      activeSession: getStoredSession(),
    };
  } catch (e) {}
  return undefined;
};

export const store = configureStore({
  reducer: {
    menuPositionData: menuPositionSlice,
    menyvelgerenData: menyvelgerenDataReducer,
    storeData: storeDataReducer,
    basketData: basketSlice,
    activeSession: activeSessionSlice,
  },
  preloadedState: loadState(),
});

store.subscribe(() => {
  const { basketData, activeSession } = store.getState();
  localStorage.setItem(LOCALSTORE_KEY, JSON.stringify(basketData));

  if (activeSession) {
    const { token, rememberToken } = activeSession;
    const primaryStorage = rememberToken ? localStorage : sessionStorage;
    const secondaryStorage = rememberToken ? sessionStorage : localStorage;

    if (primaryStorage) {
      if (token) {
        primaryStorage.setItem(TOKEN_KEY, `${token}`);
      } else {
        primaryStorage.removeItem(TOKEN_KEY);
      }
    }

    if (secondaryStorage) {
      secondaryStorage.removeItem(TOKEN_KEY);
    }
  }
});
