import { useEffect, useState } from "react";

const useCurrentPosition = () => {
  const [position, setPosition] = useState({
    valid: false,
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (
      navigator &&
      navigator.geolocation &&
      navigator.geolocation.getCurrentPosition
    ) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords && position.coords.latitude) {
            setPosition({
              valid: true,
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }
        },
        (error) => {
          setPosition({
            valid: false,
            lat: 0,
            lng: 0,
          });
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      setPosition({
        valid: false,
        lat: 0,
        lng: 0,
      });
    }
  }, []);

  return position;
};

export default useCurrentPosition;
