import { Button, Grid, Paper, Typography } from "@mui/material";
import OrderWebFrame from "../components/common/OrderWebFrame";

const NotFound = () => {
  return (
    <OrderWebFrame>
      <Paper sx={{ margin: "50px 25px", padding: "50px" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h5">
              Siden du ser etter ble ikke funnet!
            </Typography>
            <Typography>
              Beklager, siden du ser etter ble ikke funnet.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "50px" }}>
            <Button variant="outlined" href="/" disableElevation>
              Se alle stasjoner
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </OrderWebFrame>
  );
};

export default NotFound;
