import { useEffect, useState } from "react";
import { SERVICES } from "../services";

const useMenyvelgerenAddons = (store, pid, disableLoading) => {
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (store && store > 0 && pid) {
        const r = await SERVICES.fetchAddonsData(store, pid);
        const data = await r.json();
        setData(data);
      } else {
        setData(false);
      }
      setIsLoading(false);
    };

    if (!disableLoading) loadData();
  }, [store, pid, disableLoading]);

  return [data, isLoading];
};

export default useMenyvelgerenAddons;
