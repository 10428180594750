import { Button, Grid, Paper } from "@mui/material";
import { Link, useParams } from "react-router-dom";

const CheckoutEmpty = () => {
  const storeKey = useParams().store;
  return (
    <Paper
      sx={{ margin: "35px 0px", padding: "50px", textAlign: "center" }}
      elevation={4}
    >
      <Grid container>
        <Grid item xs={12}>
          <b>Din handlekurv er tom</b>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "50px" }}>
          <Button
            disableElevation
            component={Link}
            variant="contained"
            color="success"
            to={`/${storeKey}`}
          >
            Gå til meny
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CheckoutEmpty;
