import { useEffect, useState } from "react";
import { SERVICES } from "../services";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectActiveToken } from "../redux/activeSessionSlice";

const useOrderData = (store, orderid, accesskey, revision) => {
  const [orderData, setOrderData] = useState(false);
  const [lastUpdate, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userToken = useSelector(selectActiveToken);

  const loadData = async (disableLoader, isMounted) => {
    let data = false;
    if (isMounted() && !disableLoader) setIsLoading(true);
    if (store && store > 0) {
      const r = await SERVICES.fetchOrderData(
        store,
        orderid,
        accesskey,
        userToken
      );

      if (r.status === 200) {
        data = await r.json();
        if (isMounted()) {
          setLastUpdated(moment().format());
          setOrderData(data);
        }
      } else {
        if (isMounted()) setOrderData(false);
      }
    } else {
      if (isMounted()) setOrderData(false);
    }
    if (isMounted() && !disableLoader) setIsLoading(false);
    return data;
  };

  useEffect(() => {
    let mounted = true;
    const interval = setInterval(() => {
      const triggerAsyncTimer = async () => {
        const orderData = await loadData(true, () => mounted);
        if (orderData.trashed || orderData.delivered) {
          clearInterval(interval);
        }
      };
      triggerAsyncTimer();
    }, 30000);

    return () => {
      clearInterval(interval);
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, orderid, accesskey, userToken, revision]);

  useEffect(() => {
    let mounted = true;
    loadData(false, () => mounted);
    return () => (mounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, orderid, accesskey, userToken, revision]);

  return [orderData, isLoading, lastUpdate];
};

export default useOrderData;
