import styled from "styled-components";
import { COLORS } from "../../constants";
import {
  Card,
  CardContent,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddProductToCartDialog from "./AddProductToCartDialog";
import { useDispatch, useSelector } from "react-redux";
import { selectStoreHasInPrice } from "../../redux/storeDataSlice";
import { FORMATTER } from "../../services";
import {
  selectMenyvelgerenLoading,
  selectMenyvelgerenProducts,
} from "../../redux/menyvelgerenDataSlice";
import { useState } from "react";
import { setMenuPosition } from "../../redux/menuPositionSlice";

const StyledMenuProductHeader = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${COLORS.PRIMARY};

  .menu-item-header {
    position: relative;
    font-size: 22px;
    color ${COLORS.PRIMARY};
    line-height: 40px;
  }

  @media only screen and (max-width: 899px) {
    .menu-item-header {
      line-height: 50px;
    }
  }
`;

const StyledMenuProductList = styled.div`
  margin-bottom: 35px;

  &:first-of-type {
    border-top: 5px solid ${COLORS.SECONDARY};
  }
`;

const MenuProductDescriptionCard = (props) => {
  const { description } = props;
  const getFirstLine = (text) => {
    if (text) {
      return text.split("\n")[0];
    }

    return text;
  };

  const getNextLines = (text) => {
    if (text) {
      var q = text.split("\n");
      if (q.length > 1) {
        return q.slice(1).map((line, idx) => {
          return <p key={`description_line_${idx}`}>{line}</p>;
        });
      }
    }

    return [];
  };

  return (
    <Card
      sx={{ marginTop: "10px", borderBottom: `5px solid ${COLORS.SECONDARY}` }}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          {getFirstLine(description)}
        </Typography>
        <Typography variant="h5" component="div"></Typography>
        <Typography variant="body2">{getNextLines(description)}</Typography>
      </CardContent>
    </Card>
  );
};

const ProductItemList = ({ products, hideAddButton }) => {
  const hasInPrice = useSelector(selectStoreHasInPrice);

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Produkt</TableCell>
            <TableCell
              align="right"
              sx={{
                display: {
                  xs: "none",
                  sm: hasInPrice ? "table-cell" : "none",
                },
              }}
            >
              Spis&nbsp;inne
            </TableCell>
            <TableCell
              align="right"
              sx={{
                display: {
                  xs: "none",
                  sm: hasInPrice ? "table-cell" : "none",
                },
              }}
            >
              Ta&nbsp;med
            </TableCell>
            <TableCell
              align="right"
              sx={{
                display: {
                  xs: "table-cell",
                  sm: hasInPrice ? "none" : "table-cell",
                },
              }}
            >
              Pris
            </TableCell>
            {!hideAddButton && (
              <TableCell align="right">Legg&nbsp;til</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(products || []).map((row) => (
            <TableRow
              key={row.pid}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <b>{row.title}</b>
                <br />
                {row.description}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  display: {
                    xs: "none",
                    sm: hasInPrice ? "table-cell" : "none",
                  },
                }}
              >
                {FORMATTER.formatCurrent(row.price_in)}
              </TableCell>
              <TableCell align="right">
                {FORMATTER.formatCurrent(row.price_out)}
              </TableCell>
              {!hideAddButton && (
                <TableCell align="right">
                  <AddProductToCartDialog
                    product={row}
                  ></AddProductToCartDialog>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MenuProductList = ({ menu, hideAddButton }) => {
  const dispatch = useDispatch();
  const [myPosition, setPosition] = useState(0);
  const { title, mid: menuId, description } = menu || {};
  const products = useSelector((state) =>
    selectMenyvelgerenProducts(state, menuId)
  );
  const loading = useSelector(selectMenyvelgerenLoading);

  return (
    <StyledMenuProductList
      ref={(el) => {
        if (!el) return;

        const position = el.getBoundingClientRect();
        const topPosition = position.y + window.scrollY;
        const bottonPosition =
          topPosition + position.height - Math.max(window.innerHeight / 3, 0);

        if (myPosition !== bottonPosition) {
          setPosition(bottonPosition);
          dispatch(
            setMenuPosition({
              menu: menuId,
              position: {
                top: topPosition,
                bottom: bottonPosition,
              },
            })
          );
        }
      }}
    >
      <StyledMenuProductHeader>
        <div className="menu-item-header" aria-level={2}>
          {title}
        </div>
      </StyledMenuProductHeader>

      {description && <MenuProductDescriptionCard description={description} />}

      {loading && <LinearProgress color="secondary" />}
      {!loading && (
        <ProductItemList
          menu={menu}
          products={products}
          hideAddButton={hideAddButton}
        ></ProductItemList>
      )}
    </StyledMenuProductList>
  );
};

export default MenuProductList;
