import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getBasket,
  getBasketCount,
  getBasketTotals,
  getEatIn,
  setEatIn,
  setProductQty,
  setProductComment,
} from "../redux/basketSlice";
import {
  StyledListNoPad,
  StyledProductTitle,
} from "../components/checkout/CheckoutStyles";
import { FORMATTER } from "../services";
import DeleteIcon from "@mui/icons-material/Delete";
import QtyField from "../components/common/QtyField";
import { useState } from "react";
import CheckoutEmpty from "../components/checkout/CheckoutEmpty";
import { Link, useParams } from "react-router-dom";
import { ORDERWEB_MODULES } from "../constants";
import { selectStoreHasInPrice } from "../redux/storeDataSlice";

const ConfirmDeleteProduct = ({ open, setOpen, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-title">Er du sikker?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Er du sikker på at du vil fjerne produktet fra handlekurven?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}
          disableElevation
        >
          Nei, behold produktet
        </Button>
        <div style={{ flex: "1 0 0" }} />
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
            if (onConfirm) onConfirm();
          }}
          autoFocus
        >
          Ja, slett fra handlekurven
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MenyvelgerenBasket = () => {
  const dispatch = useDispatch();
  const storeKey = useParams().store;
  const totals = useSelector(getBasketTotals);
  const basketCount = useSelector(getBasketCount);
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const basket = useSelector(getBasket);
  const [deleteAlert, setDeleteAlert] = useState({
    visible: false,
    product: {},
  });
  const { sumTotal, sumTotalVat } = totals[eatIn ? "priceIn" : "priceOut"];

  const handleChangeEatIn = (event) =>
    dispatch(setEatIn(event.target.value === "true"));

  const renderAddons = (addons) => {
    return (addons || [])
      .map((addon) => {
        if (addon.option === addon.title) {
          return `- ${addon.title}`;
        } else {
          return `- ${addon.title} - ${addon.option}`;
        }
      })
      .map((item, idx) => (
        <ListItem key={`addons_list_${idx}`}>
          <ListItemText primary={item} />
        </ListItem>
      ));
  };

  const handleCommentChange = (product, visibleComment, comment) => {
    dispatch(
      setProductComment({
        id: product.id,
        visibleComment: visibleComment,
        comment: comment,
      })
    );
  };

  const handleQtyChange = (product, qty) =>
    dispatch(
      setProductQty({
        id: product.id,
        qty: qty,
      })
    );

  const handleDeleteItem = (product) =>
    setDeleteAlert({
      visible: true,
      product: product,
    });

  return basketCount === 0 ? (
    <CheckoutEmpty />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ margin: "25px 0px" }}>
          <ConfirmDeleteProduct
            open={deleteAlert.visible}
            setOpen={() => setDeleteAlert({ visible: false, product: {} })}
            onConfirm={() => dispatch(deleteProduct(deleteAlert.product.id))}
          />
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ padding: "25px 15px" }}
          >
            Din bestilling
          </Typography>
          <Divider />

          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell style={{ width: "170px" }}>&nbsp;</TableCell>
                <TableCell
                  align="right"
                  style={{ width: "80px", fontWeight: "bold" }}
                >
                  Pris
                </TableCell>
                <TableCell style={{ width: "60px" }}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(basket || []).map((item, idx) => (
                <TableRow
                  key={`basketitem_${idx}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <StyledProductTitle>
                      {item.product.title}
                    </StyledProductTitle>
                    <StyledListNoPad>
                      {renderAddons(item.addons)}
                    </StyledListNoPad>
                    {!item.visibleComment && !item.readOnly && (
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        onClick={() => handleCommentChange(item, true, "")}
                      >
                        Legg til kommentar
                      </Button>
                    )}
                    {item.visibleComment && !item.readOnly && (
                      <TextField
                        id="outlined-multiline-static"
                        label="Kommentar"
                        multiline
                        rows={4}
                        value={item.comment}
                        onChange={(e) =>
                          handleCommentChange(item, true, e.target.value)
                        }
                        sx={{
                          width: "100%",
                          maxWidth: "500px",
                          marginTop: "15px",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {!item.readOnly && (
                      <QtyField
                        value={item.qty}
                        onChange={(qty) => handleQtyChange(item, qty)}
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {FORMATTER.formatCurrent(
                      eatIn ? item.price_in : item.price_out
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {!item.readOnly && (
                      <IconButton
                        onClick={() => handleDeleteItem(item)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  {hasInPrice && (
                    <ToggleButtonGroup
                      color="secondary"
                      value={eatIn ? "true" : "false"}
                      exclusive
                      onChange={handleChangeEatIn}
                      aria-label="Platform"
                    >
                      <ToggleButton value="false">Ta med</ToggleButton>
                      <ToggleButton value="true">Spis inne</ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  Totalt ({eatIn ? "Spis inne" : "Ta med"})
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  {FORMATTER.formatCurrent(sumTotal)}
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell align="right">Inkl. mva</TableCell>
                <TableCell align="right">
                  {FORMATTER.formatCurrent(sumTotalVat)}
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "50px" }}>
        <Button
          component={Link}
          variant="contained"
          to={`/${storeKey}`}
          disableElevation
        >
          Handle mer
        </Button>
        <Button
          disableElevation
          component={Link}
          variant="contained"
          color="success"
          sx={{ float: "right" }}
          to={`/${storeKey}/${ORDERWEB_MODULES.CHECKOUT}`}
        >
          Gå til kassen &raquo;
        </Button>
      </Grid>
    </Grid>
  );
};

export default MenyvelgerenBasket;
