import {
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectStoreData } from "../../../redux/storeDataSlice";
import useAvailableDeliveryTime from "../../../hooks/useAvailableDeliveryTime";
import { StyledTimeSelector } from "../CheckoutStyles";

const DeliveryTimeSelector = ({ deliverytime, control, formData }) => {
  const storeData = useSelector(selectStoreData);
  const istimedelivery = (formData || {}).istimedelivery === "true";

  const {
    isTimeSlotAvailable,
    availableDeliveryDate,
    availableDeliveryHour,
    availableDeliveryMinute,
  } = useAvailableDeliveryTime(storeData, deliverytime);

  if (!istimedelivery) return <></>;

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controller
            name="deliveryday"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl error={!!error} variant="standard">
                <Select
                  label="Leveringstidspunkt: Dag"
                  value={value}
                  size="small"
                  onChange={onChange}
                >
                  {availableDeliveryDate.map(({ displayName, value }, idx) => (
                    <MenuItem key={`deliveryday_${idx}`} value={value}>
                      {displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <StyledTimeSelector>
            <Controller
              name="deliveryhour"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  variant="standard"
                  className="clock-input-control"
                >
                  <Select
                    className="clock-input-field"
                    label="Leveringstidspunkt: Time"
                    size="small"
                    value={value}
                    onChange={onChange}
                  >
                    {availableDeliveryHour
                      .filter(
                        (h) =>
                          isTimeSlotAvailable(formData.deliveryday, h, 0) ||
                          isTimeSlotAvailable(formData.deliveryday, h, 59)
                      )
                      .map((value, idx) => (
                        <MenuItem key={`deliveryhour_${idx}`} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />

            <Grid className="clock-separator">:</Grid>

            <Controller
              name="deliveryminute"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  error={!!error}
                  variant="standard"
                  className="clock-input-control"
                >
                  <Select
                    label="Leveringstidspunkt: Minutter"
                    className="clock-input-field"
                    value={value}
                    size="small"
                    onChange={onChange}
                  >
                    {availableDeliveryMinute
                      .filter((m) =>
                        isTimeSlotAvailable(
                          formData.deliveryday,
                          formData.deliveryhour,
                          m
                        )
                      )
                      .map((value, idx) => (
                        <MenuItem key={`deliveryminute_${idx}`} value={value}>
                          {("000" + value).slice(-2)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </StyledTimeSelector>
        </Grid>
      </Grid>
    </>
  );
};

const CheckoutStep2 = ({
  step,
  deliverytime,
  deliveryTimeLoading,
  control,
  formData,
}) => {
  const renderEstimatedDelivery = () =>
    deliveryTimeLoading ? (
      <CircularProgress size={15} thickness={5} />
    ) : deliverytime ? (
      `${deliverytime} min`
    ) : (
      ""
    );

  return (
    <>
      <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
        {step}. Når skal bestillingen hentes?
      </Typography>
      <Controller
        name="istimedelivery"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl>
            <RadioGroup
              value={value}
              onChange={onChange}
              aria-labelledby="istimedelivery-label"
              name="istimedelivery"
            >
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={
                  <>
                    Så snart som mulig.
                    <br />
                    Estimert leveringstid: {renderEstimatedDelivery()}
                  </>
                }
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label=" Velg tidspunkt"
              />
            </RadioGroup>
          </FormControl>
        )}
      />
      <DeliveryTimeSelector
        deliverytime={deliverytime}
        control={control}
        formData={formData}
      />
    </>
  );
};

export default CheckoutStep2;
