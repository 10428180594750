import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import NotFound from "./views/NotFound";
import PleaseWait from "./views/PleaseWait";
import OrderWeb from "./views/OrderWeb";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from "react-redux";
import { loadStoreData, selectStoreDataStatus } from "./redux/storeDataSlice";
import { COLORS, REDUX_DATA_STATUS } from "./constants";
import { store } from "./redux/store";
import { setCurrentStoreId } from "./redux/basketSlice";
import StoreSelector from "./views/StoreSelector";
import MyProfile from "./views/MyProfile";
import {
  loadActiveSession,
  selectActiveToken,
} from "./redux/activeSessionSlice";
import ViewOrder from "./views/ViewOrder";
import GenericTextPage from "./views/GenericTextPage";

function OrderAppDispatcher() {
  const storeDataStatus = useSelector(selectStoreDataStatus);
  if (storeDataStatus === REDUX_DATA_STATUS.IDLE) {
    return <OrderWeb />;
  } else if (storeDataStatus === REDUX_DATA_STATUS.LOADING) {
    return <PleaseWait />;
  } else {
    return <NotFound />;
  }
}

function StoreProvider({ children }) {
  const dispatch = useDispatch();
  const storeKey = useParams().store;

  useEffect(() => {
    const loadData = async () => {
      const { payload } = await dispatch(loadStoreData(storeKey));
      const { store } = payload || {};
      dispatch(setCurrentStoreId((store || {}).mid));
    };

    loadData();
  }, [storeKey, dispatch]);

  return children;
}

function AppDispatcher() {
  const dispatch = useDispatch();
  const activeToken = useSelector(selectActiveToken);

  useEffect(() => {
    dispatch(loadActiveSession(activeToken));
  }, [activeToken, dispatch]);

  return (
    <Routes>
      <Route
        exact
        path="/slett-profil"
        element={<GenericTextPage text="deleteme" />}
      />
      <Route
        exact
        path="/minside"
        element={
          <StoreProvider>
            <MyProfile />
          </StoreProvider>
        }
      />
      <Route
        exact
        path="/minside/:module"
        element={
          <StoreProvider>
            <MyProfile />
          </StoreProvider>
        }
      />
      <Route
        exact
        path="/minside/:module/:storeid/:orderid"
        element={
          <StoreProvider>
            <ViewOrder />
          </StoreProvider>
        }
      />
      <Route
        exact
        path="/:store/minside"
        element={
          <StoreProvider>
            <MyProfile />
          </StoreProvider>
        }
      />
      <Route
        exact
        path="/:store/minside/:module"
        element={
          <StoreProvider>
            <MyProfile />
          </StoreProvider>
        }
      />
      <Route
        exact
        path="/:store/minside/:module/:storeid/:orderid"
        element={
          <StoreProvider>
            <ViewOrder />
          </StoreProvider>
        }
      />
      <Route
        path="/:store"
        element={
          <StoreProvider>
            <OrderAppDispatcher />
          </StoreProvider>
        }
      />
      <Route
        path="/:store/:module"
        element={
          <StoreProvider>
            <OrderAppDispatcher />
          </StoreProvider>
        }
      />
      <Route
        path="/:store/:module/:refid"
        element={
          <StoreProvider>
            <OrderAppDispatcher />
          </StoreProvider>
        }
      />
      <Route
        exact
        path="/velg-stasjon"
        element={
          <StoreProvider>
            <StoreSelector />
          </StoreProvider>
        }
      />
      <Route exact path="/" element={<StoreSelector autoredirect />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const themeOptions = createTheme({
  palette: {
    type: "light",
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Ubuntu",
  },
});

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <AppDispatcher />
        </BrowserRouter>
      </ReduxProvider>
    </ThemeProvider>
  );
}

export default App;
