import {
  ListItem,
  ListItemButton,
  ListItemAvatar,
  List,
  ListItemText,
  Divider,
  LinearProgress,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectStoreData } from "../../redux/storeDataSlice";
import { SERVICES } from "../../services";
import { ENDPOINTS } from "../../constants";

const RenderItemSelection = ({
  item,
  editIcon,
  editableProvider,
  onClick,
  href,
  divider,
}) => {
  const { paymentProviderId, icon, title } = item;
  const rowId = `payment-list-row-${paymentProviderId}`;
  const labelId = `payment-list-label-${paymentProviderId}`;
  const hrefAddr = href ? href(item.paymentProviderId) : null;
  const iconSrc = `${ENDPOINTS.paymentBase()}${icon.substring(1)}`;

  return (
    <>
      <ListItem key={rowId}>
        <ListItemButton
          sx={{ padding: "25px 0px" }}
          href={hrefAddr}
          component="a"
          onClick={() => {
            if (onClick && editableProvider) onClick(item.paymentProviderId);
          }}
        >
          <ListItemAvatar>
            <img
              alt={title}
              aria-hidden={true}
              src={iconSrc}
              className="profile-img"
              width="40px"
              height="auto"
              style={{ marginRight: "5px" }}
            />
          </ListItemAvatar>
          <ListItemText id={labelId} primary={title} />
          {editableProvider && (
            <>
              {!editIcon && <ArrowForwardIosIcon />}
              {editIcon && (
                <>
                  Endre <EditIcon sx={{ paddingLeft: "10px" }} />
                </>
              )}
            </>
          )}
        </ListItemButton>
      </ListItem>
      {divider && <Divider />}
    </>
  );
};

const RenderSelectionList = ({
  paymentProviders,
  href,
  onClick,
  editableProvider,
}) => {
  return (
    <List sx={{ width: "calc(100% - 50px)", padding: "25px" }}>
      {paymentProviders.map((item, idx) => (
        <RenderItemSelection
          key={`paymentoption${idx}`}
          item={item}
          onClick={onClick}
          editableProvider={editableProvider}
          href={href}
          divider={idx + 1 < paymentProviders.length}
        />
      ))}
    </List>
  );
};

const RenderSelectedPayment = ({
  paymentProviders,
  href,
  onClick,
  paymentProviderId,
  editableProvider,
}) => {
  return (
    <List sx={{ width: "calc(100% - 50px)", padding: "25px" }}>
      {paymentProviders
        .filter((e) => e.paymentProviderId === paymentProviderId)
        .map((item, idx) => (
          <RenderItemSelection
            key={`paymentoption${idx}`}
            item={item}
            editableProvider={editableProvider}
            onClick={onClick}
            href={href}
            editIcon={true}
          />
        ))}
    </List>
  );
};

export const OrderPaymentOptions = ({
  storeId,
  href,
  onClick,
  paymentProviderId,
}) => {
  const storeData = useSelector(selectStoreData);
  const activeStoreId = storeId || storeData?.store?.mid;
  const [paymentProviders, setPaymentProviders] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const loadData = async () => {
      if (isMounted) setLoader(true);
      try {
        const resp = await SERVICES.fetchPaymentProviders(activeStoreId);
        if (resp && resp.length === 1) {
          if (isMounted) onClick(resp[0].paymentProviderId);
        }

        if (isMounted) setPaymentProviders(resp ? resp : []);
      } catch {
        if (isMounted) setPaymentProviders([]);
      }
      if (isMounted) setLoader(false);
    };

    if (activeStoreId) {
      loadData();
    } else {
      setPaymentProviders([]);
      setLoader(false);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStoreId]);

  const editableProvider = paymentProviders && paymentProviders.length > 1;

  return (
    <>
      {loader && <LinearProgress />}
      {!(paymentProviderId > 0) && (
        <RenderSelectionList
          href={href}
          onClick={onClick}
          paymentProviders={paymentProviders}
          editableProvider={editableProvider}
        />
      )}
      {paymentProviderId > 0 && (
        <RenderSelectedPayment
          href={href}
          onClick={onClick}
          paymentProviderId={paymentProviderId}
          paymentProviders={paymentProviders}
          editableProvider={editableProvider}
        />
      )}
    </>
  );
};
