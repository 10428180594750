import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REDUX_DATA_STATUS } from "../constants";
import { SERVICES } from "../services";

export const loadStoreData = createAsyncThunk(
  "storeData/fetchStoreData",
  async (key) => {
    if (key) {
      const r = await SERVICES.fetchStoreData(key);
      if (r && r.ok) {
        return await r.json();
      }
    }
    return false;
  }
);

export const storeDataSlice = createSlice({
  name: "storeData",
  initialState: {
    value: {},
    status: REDUX_DATA_STATUS.IDLE,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadStoreData.pending, (state) => {
        state.status = REDUX_DATA_STATUS.LOADING;
      })
      .addCase(loadStoreData.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = REDUX_DATA_STATUS.IDLE;
          state.value = action.payload;
        } else {
          state.status = REDUX_DATA_STATUS.NOT_FOUND;
          state.value = {};
        }
      });
  },
});

export const { increment } = storeDataSlice.actions;

export const selectStoreData = (state) => state.storeData.value;

export const selectStoreHasInPrice = (state) =>
  state.storeData?.value?.orders?.hasinprice ? true : false;

export const selectStoreDataStatus = (state) => state.storeData.status;

export default storeDataSlice.reducer;
