import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Step,
  StepLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  StyledListNoPad,
  StyledProductTitle,
} from "../../components/checkout/CheckoutStyles";
import { FORMATTER, SERVICES } from "../../services";
import useOrderData from "../../hooks/useOrderData";
import StyledStepper from "../../components/common/StyledStepper";
import PleaseWait from "../../views/PleaseWait";
import { OrderPaymentOptions } from "./OrderPaymentOptions";
import { ENDPOINTS, PROFILE_MODULES } from "../../constants";
import { Box } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectActiveToken } from "../../redux/activeSessionSlice";

const OrderNotFound = () => (
  <Paper sx={{ margin: "50px 25px", padding: "50px" }}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant="h5">
          Ordren du ser etter ble ikke funnet!
        </Typography>
        <Typography>Beklager, ordren du ser etter ble ikke funnet.</Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "50px" }}>
        <Button
          variant="outlined"
          href={`/${PROFILE_MODULES.ROOT}/${PROFILE_MODULES.ORDERS}`}
          disableElevation
        >
          Se alle dine ordre
        </Button>
      </Grid>
    </Grid>
  </Paper>
);

const renderStepper = (activeStep, orderCancelled, orderCancelledReason) => {
  if (orderCancelled) {
    return (
      <StyledStepper activeStep={5}>
        <Step>
          <StepLabel>
            Bestilling kansellert
            {orderCancelledReason ? `: ${orderCancelledReason}` : ""}
          </StepLabel>
        </Step>
      </StyledStepper>
    );
  } else {
    return (
      <StyledStepper activeStep={activeStep} totalnumberofsteps={5}>
        <Step>
          <StepLabel>
            {activeStep > 0 ? "Bestilling plassert" : "Venter betaling"}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>Mottatt på stasjon</StepLabel>
        </Step>
        <Step>
          <StepLabel>Bestillingen forberedes</StepLabel>
        </Step>
        <Step>
          <StepLabel>Klar for henting!</StepLabel>
        </Step>
        <Step>
          <StepLabel>Ferdig!</StepLabel>
        </Step>
      </StyledStepper>
    );
  }
};

const getOrderStep = (orderData) => {
  const { orderstatus } = orderData || {};
  switch (orderstatus) {
    default:
    case 8: // PENDING_PAYMENT = 8;
    case 0: // REGISTERED = 0;
      return 0;
    case 1: // NEW = 1;
    case 2: // RECEIVED = 2;
    case 3: // READY = 3;
      return 1;
    case 4: // IN_PRODUCTION = 4;
      return 2;
    case 5: // READY_FOR_DELIVERY = 5;
      return 3;
    case 6: // COMPLETED = 6;
    case 7: // TRASHED = 7;
      return 5;
  }
};

const OrderDetails = ({ store, orderid, orderAccessKey }) => {
  const [loader, setLoader] = useState(false);
  const [orderRevision, setOrderRevision] = useState(0);
  const userToken = useSelector(selectActiveToken);
  const [orderData, isLoading] = useOrderData(
    store,
    orderid,
    orderAccessKey,
    orderRevision
  );
  const {
    eatin,
    pickupcode,
    products,
    payments,
    orderpaymenttotal,
    ordertotal,
    vatsum,
    trashedreason,
    createdpretty,
    cashcustomer_name,
    cashcustomer_phone,
    ordercomment,
    deliverytimepretty,
    requesteddeliverytimepretty,
    deliverytype,
    orderactive,
    orderstatus,
  } = orderData || {};

  const handleCancelClick = async () => {
    setLoader(true);
    await SERVICES.cancelOrder(store, orderid, orderAccessKey, userToken);
    setOrderRevision(orderRevision + 1);
    setLoader(false);
  };

  const renderAddons = (addons) => {
    return (addons || [])
      .map((addon) => {
        if (addon.option === addon.title) {
          return `- ${addon.title}`;
        } else {
          return `- ${addon.title} - ${addon.option}`;
        }
      })
      .map((item, idx) => (
        <ListItem key={`addons_list_${idx}`}>
          <ListItemText primary={item} />
        </ListItem>
      ));
  };

  if (isLoading || loader) {
    return <PleaseWait />;
  }
  if (orderData === false) {
    return <OrderNotFound />;
  }

  const headerrows = [
    {
      key: "Bestilling",
      value: orderid,
      enabled: true,
    },
    {
      key: "Dato",
      value: createdpretty,
      enabled: true,
    },
    {
      key: "Navn",
      value: cashcustomer_name,
      enabled: true,
    },
    {
      key: "Mobil",
      value: cashcustomer_phone,
      enabled: true,
    },
    {
      key: "Hentetidspunkt",
      // value: deliverytype === "spesific" ? deliverytimepretty : "Snarest mulig",
      render: () => {
        if (deliverytype === "spesific") {
          if (deliverytimepretty === requesteddeliverytimepretty)
            return deliverytimepretty;

          return (
            <>
              <font
                aria-hidden="true"
                style={{
                  textDecoration: "line-through",
                  color: "#CCCCCC",
                  paddingRight: "10px",
                }}
              >
                {requesteddeliverytimepretty}
              </font>

              <font
                aria-label="Hentetidspunkt er utsatt"
                style={{
                  color: "#990000",
                  fontWeight: "bold",
                }}
              >
                {deliverytimepretty}
              </font>
            </>
          );
        }

        return "Snarest mulig";
      },
      enabled: orderactive,
    },
    {
      key: "",
      value: eatin ? "Spis inne" : "Ta med",
      ariaLabel: eatin ? "Ønsker å spise inne" : "Ønsker å ta med",
      enabled: orderactive,
    },
    {
      key: "Kommentar",
      value: ordercomment,
      enabled: ordercomment?.length > 0,
    },
  ];

  const orderCancelled = orderstatus === 7 || orderstatus === 9;
  const currentStep = getOrderStep(orderData);

  return (
    <>
      {currentStep >= 0 || orderCancelled
        ? renderStepper(currentStep, orderCancelled, trashedreason)
        : ""}
      {orderData.orderstatus === 8 && (
        <>
          <Alert severity="error">
            <AlertTitle>Feil med betaling</AlertTitle>
            Din bestilling er ikke sendt til stasjonen.
            <br />
            <br />
            <strong>Velg et alternativ nedenfor for å fortsette.</strong>
          </Alert>
          <OrderPaymentOptions
            storeId={orderData.mid}
            href={(id) => ENDPOINTS.payment(id, orderData.orderid)}
          />
          <Box sx={{ textAlign: "right", padding: "0px 25px 25px 25px" }}>
            <Button variant="text" onClick={handleCancelClick}>
              Avbryt bestilling
            </Button>
          </Box>
          <Divider sx={{ marginBottom: "20px" }} />
        </>
      )}
      <Table size="small" sx={{ maxWidth: "450px" }} aria-label="simple table">
        <TableBody>
          {headerrows
            .filter((e) => e.enabled)
            .map(({ key, ariaLabel, value, render }, idx) => {
              return (
                <TableRow key={`headerrow_${idx}`}>
                  <TableCell sx={{ width: "120px" }}>{key}</TableCell>
                  <TableCell aria-label={ariaLabel}>
                    {render ? render() : value}
                  </TableCell>
                </TableRow>
              );
            })}

          {orderactive && pickupcode && !orderCancelled ? (
            <TableRow key="pickupcode">
              <TableCell
                sx={{ width: "120px", fontSize: "16px", fontWeight: "bold" }}
              >
                Hentekode
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                {pickupcode}
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell
              align="right"
              style={{ width: "80px", fontWeight: "bold" }}
            >
              Pris
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(products || []).map((item, idx) => (
            <TableRow
              key={`basketitem_${idx}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <StyledProductTitle>{item.title}</StyledProductTitle>
                <StyledListNoPad>{renderAddons(item.addons)}</StyledListNoPad>
                {item.comment && (
                  <Typography
                    sx={{ fontWeight: "bold", margin: "5px 0px 0px 8px" }}
                  >
                    {item.comment}
                  </Typography>
                )}
              </TableCell>

              <TableCell align="right">
                {FORMATTER.formatCurrent(item.total_incl_vat)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Totalt
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              {FORMATTER.formatCurrent(ordertotal)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">MVA</TableCell>
            <TableCell align="right">
              {FORMATTER.formatCurrent(vatsum)}
            </TableCell>
          </TableRow>
          {(payments || []).map((item, idx) => (
            <TableRow
              key={`basketitem_${idx}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="right">
                {item.PaymentDescription}
                <br />
                {FORMATTER.formatDateTime(item.Inserted)}
              </TableCell>
              <TableCell align="right">
                {FORMATTER.formatCurrent(item.Amount)}
              </TableCell>
            </TableRow>
          ))}
          {!orderCancelled && (
            <TableRow>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
                Resterende å betale
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
                {FORMATTER.formatCurrent(ordertotal - orderpaymenttotal)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default OrderDetails;
