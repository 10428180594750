import { Container, Grid } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import CartFooter from "./CartFooter";

const OrderWebFrame = ({ disableHeader, children, hideCartCounter }) => {
  const isxxs = useMediaQuery("(max-width:400px)");

  return (
    <Grid container direction="row" spacing={0}>
      {!disableHeader && (
        <Grid item xs={12}>
          <Header />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: {
            md: "170px",
            lg: "170px",
            xl: "170px",
          },
          minHeight: {
            xs: `calc(100vh - ${isxxs ? 175 : 195}px - 5px)`,
            sm: "calc(100vh - 195px - 5px)",
            md: "calc(100vh - 210px - 5px)",
          },
        }}
      >
        <Container maxWidth="lg">{children}</Container>
      </Grid>
      {!disableHeader && (
        <Grid item xs={12}>
          <Footer />
        </Grid>
      )}
      {!hideCartCounter && <CartFooter />}
    </Grid>
  );
};

export default OrderWebFrame;
