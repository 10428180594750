import MenuProductList from "../components/menyvelgeren/MenuProductList";
import {
  ENDPOINTS,
  MENYVELGER_MENUGROUP,
  ORDERWEB_MODULES,
} from "../constants";
import ItemGrid from "../components/menyvelgeren/ItemGrid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMenuGroupById } from "../services";
import { Link, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";

export const VIEW_MODE = {
  GROUP: "GROUP",
  MENU: "MENU",
  PRODUCT: "PRODUCT",
};

const MenyvelgerenMenuGroups = ({ filter, menuData }) => {
  const storeKey = useParams().store;
  const menuGroups = (menuData || [])
    .filter((item) => item.menugroup)
    .map((item) => ({
      menugroup: item.menugroup,
    }))
    .reduce((previousValue, currentValue) => {
      const newValue = [...previousValue];
      const foundGroup = previousValue.filter(
        (e) => currentValue.menugroup === e.menugroup
      );

      if (foundGroup.length === 0) {
        newValue.push(currentValue);
      }

      return newValue;
    }, [])
    .map(({ menugroup }) => ({
      group: menugroup,
      ...getMenuGroupById(menugroup),
    }))
    .map((e) => {
      const { title, ...theRest } = e;
      return {
        text: title,
        ...theRest,
      };
    })
    .sort((a, b) => a.sortkey - b.sortkey);

  const otherMenus = (menuData || [])
    .filter((item) => !item.menugroup)
    .map((item) => ({
      text: item.title,
      icon: MENYVELGER_MENUGROUP.OTHER.icon,
      menu: item,
    }));

  const combinedMenus = [...menuGroups, ...otherMenus].map((g) => ({
    href: `/${storeKey}/${ORDERWEB_MODULES.MENULIST}/${g.group}`,
    ...g,
  }));

  return <ItemGrid items={combinedMenus}></ItemGrid>;
};

const MenyvelgerenMenuProducts = ({ filter, menuData }) => {
  const storeKey = useParams().store;
  const visibleMenuItems = (menuData || [])
    .filter((m) => {
      return m.menugroup === filter.refid;
    })
    .map((p) => ({
      text: p.title,
      href: `/${storeKey}/${ORDERWEB_MODULES.PRODUCTLIST}/${p.mid}`,
      image: ENDPOINTS.image(p.picture),
      icon: getMenuGroupById(p.menugroup).icon,
      group: p.menugroup,
    }));

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        sx={{ mb: 3 }}
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={`/${storeKey}/${ORDERWEB_MODULES.MENULIST}`}
      >
        Tilbake til oversikt
      </Button>
      <ItemGrid items={visibleMenuItems}></ItemGrid>
    </>
  );
};

const MenyvelgerenProductList = ({ filter, menuData, hideAddButton }) => {
  const storeKey = useParams().store;
  const menuProducts = menuData.filter((p) => p.mid === filter.refid).pop();
  return (
    <>
      <Button
        variant="contained"
        disableElevation
        sx={{ mb: 3 }}
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={`/${storeKey}/${ORDERWEB_MODULES.MENULIST}/${
          (menuProducts || {}).menugroup
        }`}
      >
        Tilbake til meny
      </Button>
      <MenuProductList
        key={`productlist`}
        menu={menuProducts}
        hideAddButton={hideAddButton}
      />
    </>
  );
};

const Menyvelgeren = (props) => {
  const { filter } = props;

  if (filter && filter.type === ORDERWEB_MODULES.MENULIST && filter.refid) {
    return (
      <Box sx={{ m: 2 }}>
        <MenyvelgerenMenuProducts {...props} />
      </Box>
    );
  } else if (
    filter &&
    filter.type === ORDERWEB_MODULES.PRODUCTLIST &&
    filter.refid
  ) {
    return (
      <Box sx={{ m: 2 }}>
        <MenyvelgerenProductList {...props} />
      </Box>
    );
  } else {
    return (
      <Box sx={{ m: 2 }}>
        <MenyvelgerenMenuGroups {...props} />
      </Box>
    );
  }
};

export default Menyvelgeren;
