import { createSlice } from "@reduxjs/toolkit";

export const menuPositionSlice = createSlice({
  name: "menuPosition",
  initialState: {
    positions: {},
  },
  reducers: {
    setMenuPosition: (state, { type, payload }) => {
      const { menu, position } = payload || {};
      state.positions[menu] = position;
    },
    resetMenuPositions: (state, { type, payload }) => {
      state.positions = {};
    },
  },
});

export const { setMenuPosition, resetMenuPositions } =
  menuPositionSlice.actions;

export const selectMenuPosition = (state) => state.menuPositionData?.positions;

export default menuPositionSlice.reducer;
