import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { SERVICES } from "../../services";
import { useSelector } from "react-redux";
import { selectStoreData } from "../../redux/storeDataSlice";

const GenericTextContent = ({ text, noStoreId }) => {
  const [content, setContent] = useState("");
  const storeData = useSelector(selectStoreData);

  useEffect(() => {
    const loadData = async () => {
      if (noStoreId) {
        const resp = await SERVICES.getGenericText(text, 0);
        if (resp) setContent(resp);
      } else {
        const store = storeData && storeData.store ? storeData.store : {};
        if (store && store.mid) {
          const resp = await SERVICES.getGenericText(text, store.mid);
          if (resp) setContent(resp);
        }
      }
    };
    loadData();
  }, [text, noStoreId, storeData]);

  return (
    <Grid container direction="row" sx={{ padding: "25px 0px" }}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Grid>
  );
};

export default GenericTextContent;
