import React, { useState } from "react";
import { useSelector } from "react-redux";
import { COLORS, ORDERWEB_MODULES } from "../../constants";
import { Box, Button, Divider, Grid, IconButton } from "@mui/material";
import { FORMATTER } from "../../services";
import { Link, useParams } from "react-router-dom";
import { selectStoreHasInPrice } from "../../redux/storeDataSlice";
import { getBasket, getBasketTotals, getEatIn } from "../../redux/basketSlice";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckoutOverviewProduct from "./CheckoutOverviewProduct";
import EditIcon from "@mui/icons-material/Edit";

const StyledCheckoutOverview = styled.div`
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  margin: 25px 0px;
  border-radius: 4px;
  background-color: #fff;

  .header {
    background: ${COLORS.PRIMARY};
    color: white;
    font-size: 18px;
    line-height: 35px;
    padding: 5px 15px;
    border-radius: 4px 4px 0px 0px;
  }

  .total-line {
    background: ${COLORS.PRIMARY};
    color: white;
    border-radius: 0px 0px 4px 4px;
    padding: 5px 25px 5px 15px;
  }
`;

const CheckoutProductList = () => {
  const basket = useSelector(getBasket);
  return (
    <Grid container>
      <Grid item xs={12} sx={{ padding: "5px" }}>
        {(basket || []).map((item, idx) => (
          <React.Fragment key={`product_item_${idx}`}>
            {idx > 0 && <Divider />}
            <CheckoutOverviewProduct item={item} />
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

const CheckoutOverview = () => {
  const storeKey = useParams().store;
  const [isExpanded, setExpanded] = useState(false);
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const totals = useSelector(getBasketTotals);
  const { sumTotal, sumTotalVat } = totals[eatIn ? "priceIn" : "priceOut"];

  return (
    <StyledCheckoutOverview>
      <div className="header">
        Din bestilling
        <IconButton
          component={Button}
          aria-label="expand"
          sx={{
            float: "right",
            color: "white",
            display: {
              xxs: "inherit",
              xs: "inherit",
              sm: "inherit",
              md: "none",
            },
          }}
          onClick={() => setExpanded(!isExpanded)}
        >
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      <Box
        component="div"
        className="products"
        sx={{
          display: {
            xxs: isExpanded ? "block" : "none",
            xs: isExpanded ? "block" : "none",
            sm: isExpanded ? "block" : "none",
            md: "block",
          },
        }}
      >
        <Box
          sx={{
            padding: "10px",
          }}
        >
          <Button
            variant="outlined"
            component={Link}
            to={`/${storeKey}/${ORDERWEB_MODULES.BASKET}`}
            startIcon={<EditIcon />}
          >
            Endre bestilling
          </Button>
        </Box>
        <CheckoutProductList />
      </Box>
      <div className="total-line">
        <Grid container>
          <Grid item xs={6}>
            <Grid
              item
              xs={12}
              sx={{
                fontSize: "22px",
                lineHeight: "37px",
              }}
            >
              Sum
            </Grid>
            <Grid item xs={12} sx={{ fontSize: "13px" }}>
              Inkl mva: {FORMATTER.formatCurrent(sumTotalVat)}
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "right",
              fontSize: "24px",
              lineHeight: "37px",
            }}
          >
            {FORMATTER.formatCurrent(sumTotal)}
          </Grid>
        </Grid>
      </div>
    </StyledCheckoutOverview>
  );
};

export default CheckoutOverview;
