import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REDUX_DATA_STATUS } from "../constants";
import { SERVICES } from "../services";

export const loadActiveSession = createAsyncThunk(
  "activeSession/loadActiveSession",
  async (token) => {
    if (token) {
      const data = await SERVICES.fetchUserProfile(token);
      if (data && data.customer && data.status === "VALID") {
        return data.customer ? data.customer : null;
      }
    }
    return false;
  }
);

export const signOutSession = createAsyncThunk(
  "activeSession/signOutSession",
  async (token) => {
    if (token) {
      await SERVICES.signOutSession(token);
    }
  }
);

export const activeSessionSlice = createSlice({
  name: "activeSession",
  initialState: {
    customer: null,
    token: "",
    rememberToken: true,
    status: REDUX_DATA_STATUS.IDLE,
  },
  reducers: {
    setToken: (state, { type, payload }) => {
      const { token, remember } = payload || {};
      state.token = token;
      state.rememberToken = remember ? true : false;
    },
    setCustomerData: (state, { type, payload }) => {
      state.customer = payload ? payload : null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadActiveSession.pending, (state) => {
        state.status = REDUX_DATA_STATUS.LOADING;
      })
      .addCase(loadActiveSession.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = REDUX_DATA_STATUS.IDLE;
          state.customer = action.payload ? action.payload : null;
        } else {
          state.status = REDUX_DATA_STATUS.NOT_FOUND;
          state.customer = null;
          state.token = false;
        }
      })
      .addCase(signOutSession.pending, (state) => {
        state.status = REDUX_DATA_STATUS.LOADING;
      })
      .addCase(signOutSession.fulfilled, (state, action) => {
        state.status = REDUX_DATA_STATUS.IDLE;
        state.customer = null;
        state.token = false;
      });
  },
});

export const { setToken, setCustomerData } = activeSessionSlice.actions;

export const selectActiveUserData = (state) => state.activeSession?.customer;
export const selectActiveToken = (state) => state.activeSession?.token;
export const selectActiveUserDataStatus = (state) =>
  state.activeSession?.status;

export default activeSessionSlice.reducer;
