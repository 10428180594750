import { Typography } from "@mui/material";
import { OrderPaymentOptions } from "../../common/OrderPaymentOptions";

const CheckoutStep6 = ({ step, paymentProviderId, onChangePaymentOption }) => {
  return (
    <>
      <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
        {step}. Velg betalingsmåte
      </Typography>
      <OrderPaymentOptions
        onClick={(id) =>
          paymentProviderId > 0
            ? onChangePaymentOption(0)
            : onChangePaymentOption(id)
        }
        paymentProviderId={paymentProviderId}
      />
    </>
  );
};

export default CheckoutStep6;
