import React, { useState } from "react";
import {
  Typography,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { SERVICES } from "../../services";
import { useSelector } from "react-redux";
import { selectActiveToken } from "../../redux/activeSessionSlice";

const DeleteMyAccount = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const handleCancelDeletion = () => setConfirmDeleteOpen(false);
  const activeToken = useSelector(selectActiveToken);
  const handleDeletionConfirmed = async () => {
    setConfirmDeleteOpen(false);

    const resp = await SERVICES.deleteMyProfile(activeToken);
    if (resp.status === "OK") {
      window.location = "/";
    }
  };

  return (
    <>
      <Avatar
        sx={{
          bgcolor: "red",
          float: "left",
          margin: {
            xs: "10px 5px 10px 0px",
            sm: "10px 15px",
          },
          transform: {
            xs: "scale(0.7)",
            sm: "scale(1)",
          },
        }}
      >
        <DeleteIcon />
      </Avatar>
      <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
        Slett min profil
      </Typography>

      <Box sx={{ p: 2 }}>
        <Typography>
          Ønsker du å slette din profil permanent? Her kan du slette alle data
          vi har lagret om deg:
        </Typography>

        <Typography component="div">
          <ul>
            <li>Profil</li>
            <li>Bonussaldo og transaksjoner</li>
            <li>Ordrehistorikk</li>
            <li>Favoritter</li>
          </ul>
        </Typography>

        <Typography>
          Vi vil oppbevare en kopi av dine data i 30 dager. Etter{" "}
          {moment().add(30, "days").format("DD.MM.YYYY")}
          vil alle dine data være borte. Du kan ikke bruke din profil i denne
          perioden.
        </Typography>

        <Button
          variant="contained"
          color="error"
          sx={{ marginTop: "25px" }}
          onClick={() => setConfirmDeleteOpen(true)}
        >
          Slett min profil og bonus permanent
        </Button>
      </Box>
      <Dialog
        open={confirmDeleteOpen}
        onClose={handleCancelDeletion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
      >
        <DialogTitle id="alert-dialog-title">
          Vil du slette din profil permanent?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{
                padding: "25px 0px",
                width: fullScreen ? "100%" : "500px",
              }}
            >
              Alle dine data, inkludert bonussaldo, vil bli slettet.
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCancelDeletion}>
            Nei, ta meg tilbake
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletionConfirmed}
          >
            Ja, slett min profil!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteMyAccount;
