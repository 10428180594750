import { Grid, Link, Box, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import StoreOpeningHours from "../components/common/StoreOpeningHours";
import { selectStoreData } from "../redux/storeDataSlice";

const ContactUs = () => {
  const storeData = useSelector(selectStoreData);
  const store = storeData && storeData.store ? storeData.store : {};
  const embeddedMapUrl =
    store && store.lat && store.lng
      ? `https://maps.google.com/maps?q=@${store.lat},${store.lng}&z=11&output=embed`
      : false;
  const mapUrl = store
    ? `https://www.google.com/maps/@${store.lat},${store.lng},14z`
    : false;

  return (
    <Grid container direction="row" sx={{ padding: "25px 0px" }}>
      <Grid item xs={12} md={6} sx={{ paddingBottom: "25px" }}>
        <Divider
          sx={{ marginBottom: "10px", fontWeight: "bold", width: "330px" }}
        >
          Kontakt oss
        </Divider>
        <Box sx={{ margin: "35px" }}>
          <p>
            <b>{store.title}</b>
          </p>
          {store.legalname && <p>Juridisk navn: {store.legalname}</p>}
          {store.orgno && <p>Org. nr: {store.orgno}</p>}
          <p>
            <Link href={mapUrl} target="_blank">
              {store.address}
            </Link>
          </p>
          {store.phone && (
            <p>
              Tlf. <Link href={`tel:${store.phone}`}>{store.phone}</Link>
            </p>
          )}
          {store.email && (
            <p>
              E-post: <Link href={`mailto:${store.email}`}>{store.email}</Link>
            </p>
          )}
        </Box>
        <Box sx={{ marginTop: "35px" }}>
          <StoreOpeningHours
            title="Åpningstider"
            openingHours={storeData.openinghours}
          />
        </Box>
      </Grid>
      {embeddedMapUrl && (
        <Grid item xs={12} md={6}>
          <iframe
            title="Kart og veibeskrivelse"
            style={{ height: "500px", width: "100%" }}
            src={embeddedMapUrl}
            scrolling="no"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ContactUs;
