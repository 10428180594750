import { useEffect, useState } from "react";
import { SERVICES } from "../services";

const useEstimatedDeliveryTime = (store) => {
  const [deliveryTime, setDeliveryTime] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const loadData = async () => {
      if (isMounted) setIsLoading(true);
      if (store && store > 0) {
        const r = await SERVICES.fetchDeliveryTime(store);
        const data = await r.json();
        if (isMounted) setDeliveryTime(data);
      } else {
        if (isMounted) setDeliveryTime(false);
      }
      if (isMounted) setIsLoading(false);
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [store]);

  return [deliveryTime, isLoading];
};

export default useEstimatedDeliveryTime;
