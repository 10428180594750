import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Divider,
  Paper,
  Button,
  LinearProgress,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { FORMATTER, SERVICES } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStoreData,
  selectStoreHasInPrice,
} from "../../redux/storeDataSlice";
import {
  checkoutCompleted,
  getBasket,
  getBasketTotals,
  getEatIn,
  getUseBonus,
  getOrderComment,
  setEatIn,
  setUseBonus,
  setOrderComment,
} from "../../redux/basketSlice";
import { ORDERWEB_MODULES } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectActiveToken,
  selectActiveUserData,
} from "../../redux/activeSessionSlice";
import LoginForm from "../common/LoginForm";
import CheckoutStep1 from "./steps/Step1_About";
import CheckoutStep2 from "./steps/Step2_When";
import CheckoutStep4 from "./steps/Step4_EatIn";
import CheckoutStep5 from "./steps/Step5_Bonus";
import CheckoutStep6 from "./steps/Step6_Payment";
import useAvailableDeliveryTime from "../../hooks/useAvailableDeliveryTime";
import useEstimatedDeliveryTime from "../../hooks/useEstimatedDeliveryTime";
import useAvailableBonusUse from "../../hooks/useAvailableBonusUse";

// Navn, Telefon
//  - Opprett konto

// Kommentar til bestilling

// Spis inne / spis ute

// Hentetidspunkt (krever konto?)

const StyledForm = styled.form`
  .MuiFormControl-root {
    width: calc(100% - 50px);
    margin: 15px 25px 15px 25px;
  }
`;

const LoginModal = ({
  loginModalVisible,
  setLoginModalVisible,
  createNewAccount,
}) => {
  const handleClose = () => setLoginModalVisible(false);
  const handleLogin = () => setLoginModalVisible(false);

  return (
    <Dialog
      open={loginModalVisible}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoginForm
          onLogin={handleLogin}
          defaultCreateAccount={createNewAccount}
        />
      </DialogContent>
    </Dialog>
  );
};

const OrderTotal = ({ availableForUse }) => {
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const useBonus = useSelector(getUseBonus);
  const eatIn = hasInPrice ? eatInRaw : false;
  const totals = useSelector(getBasketTotals);
  const { sumTotal } = totals[eatIn ? "priceIn" : "priceOut"];
  const restToPay = sumTotal - (useBonus ? availableForUse : 0);

  return (
    <Grid
      container
      sx={{
        padding: "0px 25px",
      }}
    >
      <Grid item xs={6}>
        <Grid item xs={12} sx={{ fontSize: "22px", lineHeight: "37px" }}>
          Å betale
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          textAlign: "right",
          fontSize: "24px",
          lineHeight: "37px",
        }}
      >
        {FORMATTER.formatCurrent(restToPay)}
      </Grid>
    </Grid>
  );
};

const CheckoutPersonalInfo = ({ setMasterLoader }) => {
  const dispatch = useDispatch();
  const storeKey = useParams().store;
  const navigate = useNavigate();
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginCreateNewAccount, setLoginCreateNewAccount] = useState(false);
  const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const storeData = useSelector(selectStoreData);
  const basket = useSelector(getBasket);
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const activeToken = useSelector(selectActiveToken);
  const activeUser = useSelector(selectActiveUserData);
  const useBonus = useSelector(getUseBonus);
  const defaultOrderComment = useSelector(getOrderComment);
  const { availableForUse } = useAvailableBonusUse(activeToken);
  const showLoginPrompt = !(activeUser && activeUser.custid > 0);
  const [{ deliverytime }, deliveryTimeLoading] = useEstimatedDeliveryTime(
    storeData && storeData.store ? storeData.store.mid : 0
  );

  const { defaultDeliveryDay, defaultDeliveryHour, defaultDeliveryMinute } =
    useAvailableDeliveryTime(storeData, deliverytime);

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      fullName: !showLoginPrompt ? activeUser.name : "",
      phoneNumber: !showLoginPrompt ? activeUser.phone : "",
      eatin: eatIn ? "true" : "false",
      paymentprovider: 0,
      istimedelivery: "false",
      createAccount: false,
      useBonus: useBonus,
      deliveryday: defaultDeliveryDay,
      deliveryhour: defaultDeliveryHour,
      deliveryminute: defaultDeliveryMinute,
      ordercomment: defaultOrderComment ? defaultOrderComment : "",
    },
  });
  const formData = watch();

  const onSubmit = async (data) => {
    setLoadingPlaceOrder(true);
    const resp = await SERVICES.placeOrder(
      storeData.store.mid,
      activeToken,
      useBonus ? availableForUse : 0,
      basket,
      data
    );
    if (resp.status === "ERROR") {
      setOrderError(
        "Det oppsto dessverre en feil ved behandling av din bestilling. Prøv igjen senere."
      );
      setLoadingPlaceOrder(false);
    } else if (resp.status === "EMPTY") {
      setOrderError("Ingen registrerte produkter på din bestilling");
      setLoadingPlaceOrder(false);
    } else if (resp.status === "CLOSED") {
      setOrderError("Stasjonen er dessverre stengt.");
      setLoadingPlaceOrder(false);
    } else if (resp.status === "NO_ACCESS") {
      setOrderError("Du må logge inn før du kan sende inn en bestilling");
      setLoadingPlaceOrder(false);
    } else if (resp.status === "SALDO_SALDO") {
      setOrderError(
        "Du har ikke nok bonus på kontoen til å kunne redusere beløpet"
      );
      setLoadingPlaceOrder(false);
    } else if (resp.status === "OK" || resp.status === "REDIRECT") {
      dispatch(checkoutCompleted());
      if (resp.paymenturl && resp.status === "REDIRECT") {
        setMasterLoader(true);
        window.location = resp.paymenturl;
      } else {
        navigate(
          `/${storeKey}/${ORDERWEB_MODULES.CONFIRM}?pk=${resp.orderid}&a=${resp.accesskey}`
        );
      }
    } else {
      setOrderError(
        "Det oppsto en ukjent feil ved behandling av din bestilling."
      );
      setLoadingPlaceOrder(false);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "eatin") {
        dispatch(setEatIn(value.eatin === "true"));
      } else if (name === "useBonus") {
        dispatch(setUseBonus(value.useBonus));
      } else if (name === "ordercomment") {
        dispatch(setOrderComment(value.ordercomment));
      } else if (name === "phoneNumber") {
        if (value.phoneNumber) {
          const newValue = value.phoneNumber.replace(/ /g, "");
          if (newValue !== value.phoneNumber) {
            setValue(name, newValue, { shouldValidate: true });
          }
        }
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (activeUser) {
      setValue("fullName", activeUser.name);
      setValue("phoneNumber", activeUser.phone);
    }
  }, [setValue, activeUser]);

  useEffect(() => {
    setValue("deliveryday", defaultDeliveryDay);
    setValue("deliveryhour", defaultDeliveryHour);
    setValue("deliveryminute", defaultDeliveryMinute);
    setValue("useBonus", useBonus);
  }, [
    setValue,
    defaultDeliveryDay,
    defaultDeliveryHour,
    defaultDeliveryMinute,
    useBonus,
  ]);

  const setPaymentProvider = (paymentProviderId) =>
    setValue("paymentprovider", paymentProviderId);

  const checkoutSteps = [
    (idx) => (
      <CheckoutStep1
        key="checkoutstep1"
        step={idx + 1}
        requireLogin
        control={control}
        showLoginPrompt={showLoginPrompt}
        handleLoginClick={(e, createAccount) => {
          setLoginModalVisible(true);
          setLoginCreateNewAccount(createAccount);
        }}
      />
    ),
    (idx) => (
      <CheckoutStep2
        key="checkoutstep2"
        step={idx + 1}
        control={control}
        formData={formData}
        deliverytime={deliverytime}
        deliveryTimeLoading={deliveryTimeLoading}
      />
    ),
  ];

  if (hasInPrice) {
    checkoutSteps.push((idx) => (
      <CheckoutStep4 key="checkoutstep4" step={idx + 1} control={control} />
    ));
  }

  checkoutSteps.push((idx) => (
    <CheckoutStep5
      key="checkoutstep5"
      step={idx + 1}
      control={control}
      availableForUse={availableForUse}
    />
  ));

  checkoutSteps.push((idx) => (
    <CheckoutStep6
      key="checkoutstep6"
      step={idx + 1}
      onChangePaymentOption={setPaymentProvider}
      paymentProviderId={formData.paymentprovider}
    />
  ));

  const enableConfigButton = formData.paymentprovider > 0;

  return (
    <>
      <LoginModal
        createNewAccount={loginCreateNewAccount}
        loginModalVisible={loginModalVisible}
        setLoginModalVisible={setLoginModalVisible}
      />
      <Paper
        sx={{
          margin: {
            xs: "25px 0px 25px 0px",
            sm: "25px 0px 25px 0px",
            md: "25px 25px 25px 0px",
          },
          minHeight: "250px",
          padding: "10px",
        }}
        elevation={3}
      >
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          {checkoutSteps.map((checkoutstep, idx) => (
            <Fragment key={`stepdivider${idx}`}>
              {idx > 0 && (
                <Divider
                  sx={{ marginTop: "35px", marginBottom: "20px" }}
                ></Divider>
              )}
              {checkoutstep(idx)}
            </Fragment>
          ))}
          <Divider sx={{ marginTop: "35px", marginBottom: "20px" }}></Divider>
          <OrderTotal availableForUse={availableForUse} />
          <Box
            sx={{
              marginTop: "35px",
              height: orderError ? "175px" : "50px",
            }}
          >
            {orderError && (
              <Alert sx={{ marginBottom: "25px" }} severity="error">
                <AlertTitle>Kan ikke behandle din bestilling</AlertTitle>
                {orderError}
              </Alert>
            )}
            {loadingPlaceOrder ? (
              <LinearProgress
                sx={{
                  float: "right",
                  width: "220px",
                  height: "36px",
                  borderRadius: "4px",
                }}
                color="success"
              />
            ) : (
              <Button
                sx={{ float: "right", width: "220px" }}
                color="success"
                variant="contained"
                type="submit"
                disableElevation
                disabled={showLoginPrompt || !enableConfigButton}
              >
                Bekreft bestilling &raquo;
              </Button>
            )}
          </Box>
          {showLoginPrompt && (
            <Alert sx={{ marginBottom: "25px" }} severity="info">
              <AlertTitle>
                Du må logge inn eller opprette konto før du kan sende
                bestillingen
              </AlertTitle>
              {orderError}
            </Alert>
          )}
        </StyledForm>
      </Paper>
    </>
  );
};

export default CheckoutPersonalInfo;
