import React, { useEffect, useState } from "react";
import moment from "moment";
import { FORMATTER, SERVICES } from "../../services";
import { Paper, Typography, Avatar } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { selectActiveToken } from "../../redux/activeSessionSlice";
import PleaseWait from "../../views/PleaseWait";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { COLORS } from "../../constants";

const MyBonus = () => {
  const [loading, setLoading] = useState(true);
  const [bonusData, setBonusData] = useState(false);
  const activeToken = useSelector(selectActiveToken);

  useEffect(() => {
    const loadData = async () => {
      const bonus = await SERVICES.fetchUserBonus(activeToken);
      setBonusData(bonus);
      setLoading(false);
    };
    if (activeToken) loadData();
  }, [activeToken]);

  if (loading) {
    return <PleaseWait />;
  }

  if (activeToken && bonusData) {
    const { status, date, saldo } = bonusData || {};

    return (
      <Paper
        sx={{
          margin: "25px 0px 25px 0px",
          minHeight: {
            xs: "175px",
            sm: "250px",
          },
          padding: "10px",
        }}
        elevation={3}
      >
        <Avatar
          sx={{
            bgcolor: COLORS.PRIMARY,
            float: "left",
            margin: "10px 15px",
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          <AccountBalanceIcon />
        </Avatar>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            padding: "15px",
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          Din bonussaldo
        </Typography>

        <Box m={5} sx={{ textAlign: "center" }}>
          <Typography variant="p" component="p">
            Din saldo pr. {moment(date).format("DD.MM.YYYY")}:
          </Typography>

          <Typography variant="h6" component="p" sx={{ marginTop: "15px" }}>
            {status === "VALID" ? FORMATTER.formatCurrent(saldo) : ""}
          </Typography>
        </Box>
      </Paper>
    );
  }
};

export default MyBonus;
