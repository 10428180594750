import { COLORS, ORDERWEB_MODULES, PROGRAM_OWNER } from "../../constants";
import { Box, Container, Grid, Link } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectStoreData } from "../../redux/storeDataSlice";
import { useParams } from "react-router-dom";

const StyledFooter = styled.div`
  border-top: 5px solid ${COLORS.SECONDARY};

  .footer-wrapper {
    background: ${COLORS.PRIMARY};
    color: white;
    padding: 15px 0px;
  }
`;

const StyledFooterLogo = styled.div`
  padding: 10px 25px;
  img {
    max-width: 75px;
    width: 100%;
  }
`;

const Footer = () => {
  const storeKey = useParams().store;
  const storeData = useSelector(selectStoreData);
  const store = storeData && storeData.store ? storeData.store : {};
  const getMapUrl = () =>
    store ? `https://www.google.com/maps/@${store.lat},${store.lng},14z` : "";

  return (
    <StyledFooter>
      <div className="footer-wrapper">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <StyledFooterLogo>
                <img
                  alt="Best Logo"
                  aria-hidden="true"
                  src={PROGRAM_OWNER.logofull}
                />
              </StyledFooterLogo>
            </Grid>
            {store && store.title && (
              <Grid item xs={12} sm={6} md={4}>
                <p>
                  <b>{store.title}</b>
                </p>
                <p>
                  <Link
                    href={getMapUrl()}
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    {store.address}
                  </Link>
                </p>
                {store.phone && (
                  <p>
                    <Link
                      href={`tel:${store.phone}`}
                      style={{ color: "white" }}
                    >
                      Tlf. {store.phone}
                    </Link>
                  </p>
                )}
                <Box
                  sx={{
                    display: {
                      xxs: "block",
                      xs: "block",
                      sm: "none",
                    },
                    height: "50px",
                  }}
                ></Box>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <p>
                <b>{PROGRAM_OWNER.title}</b>
              </p>

              {storeKey && (
                <>
                  <p>
                    <Link
                      style={{ color: "white", margin: "0px 10px" }}
                      component={ReactRouterLink}
                      to={`/${storeKey}/${ORDERWEB_MODULES.SALESTERMS}`}
                    >
                      &raquo; Salgsbetingelser
                    </Link>
                  </p>
                  <p>
                    <Link
                      style={{ color: "white", margin: "0px 10px" }}
                      component={ReactRouterLink}
                      to={`/${storeKey}/${ORDERWEB_MODULES.ABOUTPROGRAM}`}
                    >
                      &raquo; Om bonusprogrammet
                    </Link>
                  </p>
                  <p>
                    <Link
                      style={{ color: "white", margin: "0px 10px" }}
                      component={ReactRouterLink}
                      to={`/${storeKey}/${ORDERWEB_MODULES.DELETEME}`}
                    >
                      &raquo; Slett din profil
                    </Link>
                  </p>
                </>
              )}

              <p>
                {PROGRAM_OWNER.phone && (
                  <Link
                    href={`tel:${PROGRAM_OWNER.phone}`}
                    style={{ color: "white" }}
                  >
                    Tlf. {PROGRAM_OWNER.phone}
                  </Link>
                )}
                &nbsp;|&nbsp;
                <Link
                  href={`mailto:${PROGRAM_OWNER.email}`}
                  style={{ color: "white" }}
                >
                  {PROGRAM_OWNER.email}
                </Link>
              </p>
              <p>
                <span target="_blank" style={{ color: "white" }}>
                  {PROGRAM_OWNER.slogan}
                </span>
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </StyledFooter>
  );
};

export default Footer;
