import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBasket, getBasketTotals, getEatIn } from "../redux/basketSlice";
import {
  selectStoreData,
  selectStoreHasInPrice,
} from "../redux/storeDataSlice";
import { SERVICES } from "../services";

const calculateAvailableBonus = (
  storeData,
  basket,
  eatIn,
  sumTotal,
  bonusSaldo
) => {
  const productBonusAmount = storeData?.store?.productbonusamount;
  const productBonusMin = storeData?.store?.productbonusmin;
  const productbonusenabled = storeData?.store?.productbonusenabled;
  const currentPriceKey = eatIn ? "price_in" : "price_out";

  if (productbonusenabled && productBonusAmount > 0) {
    let possiblebonus = 0;
    let restbonus = bonusSaldo;

    for (let i in basket) {
      const no_use_bonus =
        basket[i] &&
        basket[i].product &&
        basket[i].product.no_use_bonus === "1";

      const no_use_bonus_override =
        basket[i] && basket[i].no_use_bonus_override;

      if (!no_use_bonus && !no_use_bonus_override) {
        const qty = Math.max(basket[i]["qty"] ? basket[i]["qty"] * 1 : 1, 1);
        if (basket[i][currentPriceKey] * 1 >= productBonusMin) {
          restbonus -= productBonusAmount * 1;
          if (restbonus >= productBonusAmount) {
            possiblebonus += productBonusAmount * qty;
          }
        }
      }
    }

    if (sumTotal < possiblebonus) {
      possiblebonus = sumTotal;
    }

    if (possiblebonus > bonusSaldo) {
      possiblebonus = bonusSaldo;
    }

    return possiblebonus;
  }
  return 0;
};

const useAvailableBonusUse = (activeToken) => {
  const [availableBonusUse, setAvailableBonusUse] = useState({
    availableBonus: 0,
    availableForUse: 0,
  });
  const [bonusData, setBonusData] = useState(false);
  const storeData = useSelector(selectStoreData);
  const basket = useSelector(getBasket);
  const basketTotals = useSelector(getBasketTotals);
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const { sumTotal } = basketTotals[eatIn ? "priceIn" : "priceOut"];

  useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      const bonus = await SERVICES.fetchUserBonus(activeToken);
      if (isMounted) setBonusData(bonus);
    };
    if (activeToken) {
      if (isMounted) loadData();
    } else {
      if (isMounted) setBonusData(false);
    }
    return () => {
      isMounted = false;
    };
  }, [activeToken]);

  useEffect(() => {
    const getData = () => {
      const { saldo, status } = bonusData;

      if (status === "VALID" && parseFloat(saldo)) {
        const parsedSaldo = parseFloat(saldo);

        return {
          availableBonus: parsedSaldo,
          availableForUse: calculateAvailableBonus(
            storeData,
            basket,
            eatIn,
            sumTotal,
            parsedSaldo
          ),
        };
      }
      return {
        availableBonus: 0,
        availableForUse: 0,
      };
    };

    setAvailableBonusUse(getData);
  }, [basket, bonusData, eatIn, storeData, sumTotal]);

  return availableBonusUse;
};

export default useAvailableBonusUse;
