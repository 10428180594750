import { Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createProfileLink, PROFILE_MODULES } from "../../../constants";
import { useState } from "react";
import {
  selectActiveToken,
  signOutSession,
} from "../../../redux/activeSessionSlice";
import { StyledMenuIconButton } from "./HeaderStyles";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useParams } from "react-router-dom";

const UserAccountButton = ({ color }) => {
  const storeKey = useParams().store;
  const dispatch = useDispatch();
  const activeToken = useSelector(selectActiveToken);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();
    dispatch(signOutSession(activeToken));
  };

  return (
    <>
      <StyledMenuIconButton
        sx={{
          color: color,
          margin: {
            md: "16px 0px",
            lg: "16px 0px",
            xl: "16px 0px",
          },
        }}
        id="account-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {color === "primary" ? <PersonIcon /> : <PersonIcon />}
      </StyledMenuIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          component={Link}
          to={createProfileLink(storeKey, PROFILE_MODULES.BONUS)}
          onClick={handleClose}
        >
          Opptjent bonus
        </MenuItem>
        <MenuItem
          component={Link}
          to={createProfileLink(storeKey, PROFILE_MODULES.ORDERS)}
          onClick={handleClose}
        >
          Bestillinger
        </MenuItem>
        <MenuItem
          component={Link}
          to={createProfileLink(storeKey, PROFILE_MODULES.PROFILE)}
          onClick={handleClose}
        >
          Din profil
        </MenuItem>
        {activeToken && <MenuItem onClick={handleSignOut}>Logg av</MenuItem>}
      </Menu>
    </>
  );
};

export default UserAccountButton;
