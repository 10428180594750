import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {
  getBasketCount,
  getBasketTotals,
  getEatIn,
} from "../../redux/basketSlice";
import { COLORS, ORDERWEB_MODULES } from "../../constants";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectStoreHasInPrice } from "../../redux/storeDataSlice";
import { useNavigate, useParams } from "react-router-dom";
import { FORMATTER } from "../../services";

const StyledCartFooter = styled.div`
  position: fixed;
  width: calc(100vw - 15px);
  max-width: 500px;
  height: 47px;
  background: ${COLORS.SECONDARY};
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  margin: 0 auto;
  padding: 0;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
  transition: bottom 0.4s ease;
  bottom: ${({ isVisible }) => (isVisible ? "0px" : "-50px")};

  .cart-icon {
    float: left;
    color: white;
    padding: 12px 5px 8px 15px;
  }

  .cart-count {
    float: left;
  }

  .cart-total {
    float: right;
  }

  .cart-count,
  .cart-total {
    color: white;
    line-height: 47px;
    padding: 0px 10px;
    font-size: 16px;
  }
`;

const CartFooter = () => {
  const storeKey = useParams().store;
  const module = useParams().module;
  const navigate = useNavigate();
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const basketCount = useSelector(getBasketCount);
  const totals = useSelector(getBasketTotals);
  const { sumTotal } = totals[eatIn ? "priceIn" : "priceOut"];

  const handleClick = () =>
    navigate(`/${storeKey}/${ORDERWEB_MODULES.CHECKOUT}`);

  const enabled =
    storeKey &&
    basketCount > 0 &&
    ![
      ORDERWEB_MODULES.CHECKOUT,
      ORDERWEB_MODULES.BASKET,
      ORDERWEB_MODULES.CONFIRM,
    ].includes(module);

  return (
    <StyledCartFooter onClick={handleClick} isVisible={enabled}>
      <div className="cart-icon">
        <ShoppingCartCheckoutIcon />
      </div>
      <div className="cart-count">
        {basketCount} produkt{basketCount !== 1 ? "er" : ""}
      </div>
      <div style={{ flex: "1 0 0" }} />
      <div className="cart-total">{FORMATTER.formatCurrent(sumTotal)}</div>
    </StyledCartFooter>
  );
};

export default CartFooter;
