import {
  Divider,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Typography,
  Button,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import { Controller } from "react-hook-form";
import PersonIcon from "@mui/icons-material/Person";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { useSelector } from "react-redux";
import { selectActiveUserData } from "../../../redux/activeSessionSlice";

const CheckoutStep1LoginOptional = ({
  testuser,
  handleLoginClick,
  showLoginPrompt,
  control,
}) => (
  <>
    <Controller
      name="fullName"
      control={control}
      rules={{
        required: {
          value: true,
          message: "Navn er påkrevd",
        },
        minLength: {
          value: 3,
          message: "Navn er påkrevd",
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} variant="standard">
          <InputLabel htmlFor="fullname-input">Navn</InputLabel>
          <Input
            id="fullname-input"
            label="Navn"
            sx={{ width: "100%" }}
            disabled={!showLoginPrompt}
            value={value}
            onChange={onChange}
            startAdornment={
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            }
          />
          <FormHelperText>{error ? error.message : null}</FormHelperText>
        </FormControl>
      )}
    />
    <Controller
      name="phoneNumber"
      control={control}
      rules={{
        required: {
          value: true,
          message: "Mobilnummer er påkrevd",
        },
        pattern: {
          value: testuser ? /^(.*)$/ : /^([0-9]{8})$/,
          message: "Mobilnummer kan kun bestå av 8 siffer",
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} variant="standard">
          <InputLabel htmlFor="phonenumber-input">Mobilnummer</InputLabel>
          <Input
            id="phonenumber-input"
            label="Mobilnummer"
            disabled={!showLoginPrompt}
            value={value}
            onChange={onChange}
            startAdornment={
              <InputAdornment position="start">
                <PhoneAndroidIcon />
              </InputAdornment>
            }
          />
          <FormHelperText>{error ? error.message : null}</FormHelperText>
        </FormControl>
      )}
    />
    {showLoginPrompt && (
      <>
        <Controller
          name="createAccount"
          control={control}
          render={({
            field: { onChange, value, field },
            fieldState: { error },
          }) => (
            <FormControlLabel
              sx={{ margin: "10px 15px 5px 15px" }}
              control={<Checkbox checked={value} onChange={onChange} />}
              label="Lag en konto og start opptjening av bonus"
            />
          )}
        />
        <Divider
          sx={{
            margin: "35px 100px 20px 100px",
            width: "calc(100% - 200px)",
          }}
        >
          Har du allerede konto?
        </Divider>
        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={handleLoginClick}
          >
            Logg inn
          </Button>
        </Box>
      </>
    )}
  </>
);

const CheckoutStep1LoginRequired = ({
  handleLoginClick,
  showLoginPrompt,
  control,
}) => (
  <>
    <Box sx={{ textAlign: "center", marginTop: "35px" }}>
      <Button
        variant="contained"
        color="success"
        disableElevation
        onClick={(e) => handleLoginClick(e, true)}
      >
        Opprett konto
      </Button>
    </Box>
    <Divider
      sx={{
        margin: "35px 100px 20px 100px",
        width: "calc(100% - 200px)",
      }}
    >
      Har du allerede konto?
    </Divider>
    <Box sx={{ textAlign: "center" }}>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleLoginClick}
      >
        Logg inn
      </Button>
    </Box>
  </>
);

const CheckoutStep1 = ({ step, requireLogin, ...props }) => {
  const activeUser = useSelector(selectActiveUserData);
  return (
    <>
      <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
        {step}. Litt om deg
      </Typography>

      {activeUser?.testuser && <Alert severity="warning">TESTBRUKER</Alert>}

      {requireLogin && !activeUser ? (
        <CheckoutStep1LoginRequired
          testuser={activeUser?.testuser}
          {...props}
        />
      ) : (
        <CheckoutStep1LoginOptional
          testuser={activeUser?.testuser}
          {...props}
        />
      )}
    </>
  );
};

export default CheckoutStep1;
