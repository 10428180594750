import { List } from "@mui/material";
import styled from "styled-components";

export const StyledProductTitle = styled.div`
  line-height: 28px;
  font-weight: bold;
`;

export const StyledListNoPad = styled(List)`
  padding: 0;
  margin: 0;

  .MuiListItem-root {
    padding: 0;
    margin: 0;
  }

  .MuiListItem-root {
    padding: 0;
    margin: 0;
  }

  .MuiListItemText-root {
    padding: 0;
    margin: 0;
  }

  .qty-line {
    padding-bottom: 5px;
  }
`;

export const StyledTimeSelector = styled.div`
  margin: 15px 25px;
  display: flex;

  .clock-separator {
    line-height: 30px;
    padding: 0px 10px;
  }

  .clock-input-control.MuiFormControl-root {
    width: 75px;
    padding: 0;
    margin: 0;
    height: 30px;
  }

  .clock-input-field {
    width: 75px;
  }
`;
