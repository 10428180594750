import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

const StoreOpeningHours = ({ title, openingHours }) => (
  <Box sx={{ width: "330px" }}>
    <Divider sx={{ marginBottom: "10px", fontWeight: "bold", width: "330px" }}>
      {title}
    </Divider>
    <Table
      size="small"
      aria-label="a dense table"
      sx={{ width: "300px", marginLeft: "15px" }}
    >
      <TableBody>
        {(openingHours || []).map((row, idx) => (
          <TableRow key={`openinghrs_${idx}`}>
            <TableCell
              component="th"
              scope="row"
              sx={{ fontWeight: row.today ? "bold" : "normal" }}
            >
              {row.dayname}
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: row.today ? "bold" : "normal" }}
            >
              {row.openinghrs} - {row.closinghrs}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
);

export default StoreOpeningHours;
