import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { SERVICES } from "../../services";
import { useDispatch } from "react-redux";
import { loadActiveSession, setToken } from "../../redux/activeSessionSlice";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

const VIEWS = {
  LOGIN: "LOGIN",
  CREATE: "CREATE",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
};

const LoginFormFooter = ({
  showLogin,
  showForgotPassword,
  showCreateAccount,
  setView,
}) => {
  const availableButtons = {
    login: (idx) => (
      <Grid item xs={idx === 0} key="loginfooter_login">
        <Link
          onClick={() => setView(VIEWS.LOGIN)}
          component="button"
          variant="body2"
        >
          Logg inn
        </Link>
      </Grid>
    ),
    forgotpassword: (idx) => (
      <Grid item xs={idx === 0} key="loginfooter_forgotpassword">
        <Link
          onClick={() => setView(VIEWS.FORGOT_PASSWORD)}
          component="button"
          variant="body2"
        >
          Glemt passord?
        </Link>
      </Grid>
    ),
    create: (idx) => (
      <Grid item xs={idx === 0} key="loginfooter_create">
        <Link
          onClick={() => setView(VIEWS.CREATE)}
          component="button"
          variant="body2"
        >
          Har du ikke konto? Opprett nå!
        </Link>
      </Grid>
    ),
  };

  const currentButtons = [];

  if (showLogin) {
    currentButtons.push(availableButtons.login(currentButtons.length));
  }
  if (showForgotPassword) {
    currentButtons.push(availableButtons.forgotpassword(currentButtons.length));
  }
  if (showCreateAccount) {
    currentButtons.push(availableButtons.create(currentButtons.length));
  }

  return <Grid container>{currentButtons}</Grid>;
};

const RenderLoginForm = ({ defaultPhoneNumber }) => (
  <>
    <TextField
      margin="normal"
      required
      fullWidth
      id="username"
      label="Mobilnummer (8 siffer)"
      name="username"
      autoComplete="mobile"
      variant="standard"
      autoFocus
      defaultValue={defaultPhoneNumber}
    />
    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Passord"
      type="password"
      variant="standard"
      id="password"
      autoComplete="current-password"
    />
    <FormControlLabel
      control={<Checkbox id="remember" name="remember" color="primary" />}
      label="Husk med på denne enheten"
    />
  </>
);

const RenderPhoneOnlyForm = ({ defaultPhoneNumber }) => {
  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Mobilnummer (8 siffer)"
        name="username"
        autoComplete="mobile"
        variant="standard"
        autoFocus
        defaultValue={defaultPhoneNumber}
      />
    </>
  );
};

const SignInForm = ({ setView, defaultPhoneNumber, showMessage, onLogin }) => {
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const login = {
      username: data.get("username"),
      password: data.get("password"),
      remember: data.get("remember") ? true : false,
    };

    const session = await SERVICES.signInUser(login.username, login.password);
    if (session.status === "OK") {
      dispatch(setToken({ token: session.token, remember: login.remember }));
      dispatch(loadActiveSession(session.token));
      if (onLogin) onLogin();
    } else {
      showMessage("error", "Feil brukernavn eller passord");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h2" variant="h5">
          Logg inn
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <RenderLoginForm defaultPhoneNumber={defaultPhoneNumber} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{ mt: 3, mb: 2 }}
          >
            Logg inn
          </Button>

          <LoginFormFooter
            showForgotPassword
            showCreateAccount
            setView={setView}
          />
        </Box>
      </Box>
    </>
  );
};

const ForgotPassword = ({
  setView,
  setPhoneNumber,
  defaultPhoneNumber,
  showMessage,
}) => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const resp = await SERVICES.forgotPassword(username);
    if (setPhoneNumber) setPhoneNumber(username);

    if (resp === "OK") {
      setView(VIEWS.LOGIN);
      showMessage("success", "Nytt passord ble sendt på SMS.");
    } else {
      showMessage("error", "Kunne ikke sende nytt passord. Prøv igjen senere");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h2" variant="h5">
          Glemt passord?
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <RenderPhoneOnlyForm defaultPhoneNumber={defaultPhoneNumber} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{ mt: 3, mb: 2 }}
          >
            Send nytt passord
          </Button>
          <LoginFormFooter showLogin showCreateAccount setView={setView} />
        </Box>
      </Box>
    </>
  );
};

const CreateUserForm = ({
  setView,
  setPhoneNumber,
  defaultPhoneNumber,
  showMessage,
}) => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const resp = await SERVICES.forgotPassword(username);
    if (setPhoneNumber) setPhoneNumber(username);

    if (resp === "OK") {
      setView(VIEWS.LOGIN);
      showMessage("success", "Passord ble sendt på SMS.");
    } else {
      showMessage("error", "Kunne ikke sende nytt passord. Prøv igjen senere");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h2" variant="h5">
          Opprett konto
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <RenderPhoneOnlyForm defaultPhoneNumber={defaultPhoneNumber} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{ mt: 3, mb: 2 }}
          >
            Opprett konto
          </Button>
          <LoginFormFooter
            showLogin
            showForgotPassword="right"
            setView={setView}
          />
        </Box>
      </Box>
    </>
  );
};

const LoginForm = ({ onLogin, defaultCreateAccount }) => {
  const [view, setView] = useState(VIEWS.LOGIN);
  const [message, setMessage] = useState(false);
  const [defaultPhoneNumber, setDefaultPhoneNumber] = useState();
  const handleSetPhoneNumber = (phoneNumber) =>
    setDefaultPhoneNumber(phoneNumber);

  useEffect(() => {
    setView(defaultCreateAccount ? VIEWS.CREATE : VIEWS.LOGIN);
  }, [defaultCreateAccount]);

  const showMessage = (type, message) => {
    setMessage({
      type: type,
      message: message,
    });
  };

  const renderMainBody = () => {
    switch (view) {
      default:
      case VIEWS.LOGIN:
        return (
          <SignInForm
            defaultPhoneNumber={defaultPhoneNumber}
            onLogin={onLogin}
            showMessage={showMessage}
            setView={setView}
          />
        );
      case VIEWS.FORGOT_PASSWORD:
        return (
          <ForgotPassword
            defaultPhoneNumber={defaultPhoneNumber}
            setView={setView}
            showMessage={showMessage}
            setPhoneNumber={handleSetPhoneNumber}
          />
        );
      case VIEWS.CREATE:
        return (
          <CreateUserForm
            defaultPhoneNumber={defaultPhoneNumber}
            setView={setView}
            showMessage={showMessage}
            setPhoneNumber={handleSetPhoneNumber}
          />
        );
    }
  };

  return (
    <>
      {message && <Alert severity={message.type}>{message.message}</Alert>}
      {renderMainBody()}
    </>
  );
};

export default LoginForm;
