import { Container, Paper, Box, Grid, Button } from "@mui/material";
import OrderWebFrame from "../components/common/OrderWebFrame";
import OrderDetails from "../components/common/OrderDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useParams } from "react-router-dom";
import { PROFILE_MODULES } from "../constants";

const ViewOrder = () => {
  const orderid = useParams().orderid;
  const store = useParams().storeid;

  return (
    <OrderWebFrame>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ margin: "25px 0px" }}>
            <Box mb={2}>
              <Button
                component={Link}
                to={`/${PROFILE_MODULES.ROOT}/${PROFILE_MODULES.ORDERS}`}
              >
                <ArrowBackIcon />
                Tilbake
              </Button>
            </Box>

            <Container maxWidth="md">
              <Paper elevation={4}>
                <OrderDetails store={store} orderid={orderid} />
              </Paper>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </OrderWebFrame>
  );
};

export default ViewOrder;
