import moment from "moment";
import { useEffect, useState } from "react";

const getAvailableDeliveryDate = () => {
  const availableDeliveryDate = [];

  for (var i = 0; i < 5; i++) {
    var date = moment().startOf("day").add(i, "days");

    availableDeliveryDate.push({
      value: date.format("YYYY-MM-DD"),
      displayName: date.calendar(null, {
        sameDay: "[i dag], DD.MMM",
        nextDay: "[i morgen], DD.MMM",
        nextWeek: "dddd, DD.MMM",
        sameElse: "dddd, DD.MMM",
      }),
    });
  }

  return availableDeliveryDate;
};

const isTimeSlotAvailable = (
  storeData,
  estimateddelivery,
  day,
  hour,
  minute
) => {
  if (!storeData) return false;
  const { orderopeninghours } = storeData || {};
  if (!orderopeninghours || orderopeninghours.length === 0) return false;

  const targetTimestamp = moment(
    day + " " + ("000" + hour).slice(-2) + ":" + ("000" + minute).slice(-2)
  );
  let currentDayIdx = targetTimestamp.day();
  const isAfterDeliveryLimit =
    moment()
      .add(Math.max(0, estimateddelivery), "minutes")
      .diff(targetTimestamp) < 0;

  let isInsideOpeningHours = false;
  const currentDay = orderopeninghours.filter((e) =>
    currentDayIdx === 0 ? 7 : e.dayid === currentDayIdx
  );

  if (
    currentDay &&
    currentDay.length &&
    currentDay[0]["openinghrs"] &&
    currentDay[0]["closinghrs"]
  ) {
    var openinghrs = moment(day + " " + currentDay[0]["openinghrs"]);
    var closinghrs = moment(day + " " + currentDay[0]["closinghrs"]);
    var isAfterOpening = openinghrs.diff(targetTimestamp) <= 0;
    var isBeforeClosing = closinghrs.diff(targetTimestamp) >= 0;
    isInsideOpeningHours = isBeforeClosing && isAfterOpening;
  }

  return isAfterDeliveryLimit && isInsideOpeningHours;
};

const getAvailableDeliveryHour = () => {
  const availableDeliveryHour = [];
  for (var h = 0; h < 24; h++) {
    availableDeliveryHour.push(h);
  }

  return availableDeliveryHour;
};

const getAvailableDeliveryMinute = () => [0, 10, 15, 30, 45];

const getValidDefaultTimestamp = (
  storeData,
  estimateddelivery,
  days,
  hours,
  minutes
) => {
  for (let i1 in days) {
    for (let i2 in hours) {
      for (let i3 in minutes) {
        if (
          isTimeSlotAvailable(
            storeData,
            estimateddelivery,
            days[i1]["value"],
            hours[i2],
            minutes[i3]
          )
        ) {
          return {
            day: days[i1]["value"],
            hour: hours[i2],
            minute: minutes[i3],
          };
        }
      }
    }
  }
  return false;
};

const useAvailableDeliveryTime = (storeData, estimatedDeliveryTime) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const availableDeliveryDate = getAvailableDeliveryDate();
    const availableDeliveryHour = getAvailableDeliveryHour();
    const availableDeliveryMinute = getAvailableDeliveryMinute();

    const {
      day: defaultDeliveryDay,
      hour: defaultDeliveryHour,
      minute: defaultDeliveryMinute,
    } = getValidDefaultTimestamp(
      storeData,
      estimatedDeliveryTime,
      availableDeliveryDate,
      availableDeliveryHour,
      availableDeliveryMinute
    );

    setData({
      availableDeliveryDate: availableDeliveryDate,
      availableDeliveryHour: availableDeliveryHour,
      availableDeliveryMinute: availableDeliveryMinute,
      defaultDeliveryDay: defaultDeliveryDay,
      defaultDeliveryHour: defaultDeliveryHour,
      defaultDeliveryMinute: defaultDeliveryMinute,
      isTimeSlotAvailable: (day, hour, minute) =>
        isTimeSlotAvailable(
          storeData,
          estimatedDeliveryTime,
          day,
          hour,
          minute
        ),
    });
  }, [storeData, estimatedDeliveryTime]);

  return data;
};

export default useAvailableDeliveryTime;
