import {
  Avatar,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FORMATTER } from "../../services";
import LaunchIcon from "@mui/icons-material/Launch";
import ArticleIcon from "@mui/icons-material/Article";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { PROFILE_MODULES } from "../../constants";

const OrderTable = ({ orders }) => {
  const getOrderUrl = (store, orderid) =>
    `/${PROFILE_MODULES.ROOT}/${PROFILE_MODULES.ORDERS}/${store}/${orderid}`;

  return (
    <>
      <List
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          width: "100%",
        }}
      >
        {(orders || []).map((row, idx) => (
          <ListItemButton
            key={`orderlist_${idx}`}
            component={Link}
            to={getOrderUrl(row.mid, row.orderid)}
            sx={{
              color: "black",
              borderBottom: "1px solid rgba(0,0,0,0.3)",
              margin: 0,
              padding: {
                xs: "8px 8px",
                sm: "8px 16px",
              },
            }}
          >
            <ListItemAvatar
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
              }}
            >
              <Avatar>
                <ArticleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography sx={{ color: "black" }}>
                  Bestilling: {row.orderid}
                </Typography>
              }
              secondary={
                <>
                  <Box
                    component="font"
                    sx={{ display: "block", color: "black" }}
                  >
                    Sum: {FORMATTER.formatCurrent(row.ordertotal)}
                  </Box>
                  <Box component="font" sx={{ display: "block" }}>
                    {row.createdpretty}
                  </Box>
                </>
              }
            />
          </ListItemButton>
        ))}
      </List>
      <TableContainer sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Table sx={{ maxWidth: "700px" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Bestillingsnummer</TableCell>
              <TableCell>Dato</TableCell>
              <TableCell align="right">Sum</TableCell>
              <TableCell align="right">Vis bestilling</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(orders || []).map((row, idx) => (
              <TableRow key={`ordertable_${idx}`}>
                <TableCell>{row.orderid}</TableCell>
                <TableCell>{row.createdpretty}</TableCell>
                <TableCell align="right">
                  {FORMATTER.formatCurrent(row.ordertotal)}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Vis bestilling"
                    component={Link}
                    to={getOrderUrl(row.mid, row.orderid)}
                  >
                    <LaunchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderTable;
