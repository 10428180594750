import {
  Button,
  Container,
  Box,
  Typography,
  Divider,
  Grid,
  Popover,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OrderWebFrame from "../components/common/OrderWebFrame";
import { setCurrentStoreId } from "../redux/basketSlice";
import { DISTANCE_TOOLBOX, SERVICES } from "../services";
import { ORDERWEB_MODULES } from "../constants";
import useCurrentPosition from "../hooks/useUserLocation";
import { selectActiveUserData } from "../redux/activeSessionSlice";

const StoreDistance = ({ currentLocation, store }) => {
  const distance = DISTANCE_TOOLBOX.between(
    store.lat,
    store.lng,
    currentLocation.lat,
    currentLocation.lng
  );

  return currentLocation?.valid
    ? ` - ${DISTANCE_TOOLBOX.pretty(distance)}`
    : "";
};

const OrderButton = ({ store }) => {
  const { title, isopen, isopenfororders } = store || {};
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!isopen || !isopenfororders) {
    return (
      <>
        <Button
          sx={{
            marginTop: {
              xs: "25px",
              sm: "0px",
              md: "30px",
            },
            marginLeft: {
              xs: "0px",
              sm: "10px",
              md: "25px",
            },
            textAlign: "center",
            display: "block",
          }}
          component={Link}
          to={`/${store.slug}/${ORDERWEB_MODULES.MENULIST}`}
          variant="contained"
          color="primary"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          disableElevation
        >
          Se vår meny
        </Button>

        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 2 }}>
            {title} kan dessverre ikke ta i mot bestillinger akkurat nå, du kan
            fortsatt se vår meny
          </Typography>
        </Popover>
      </>
    );
  } else {
    return (
      <Button
        sx={{
          marginTop: {
            xs: "25px",
            sm: "0px",
            md: "30px",
          },
          marginLeft: {
            xs: "0px",
            sm: "10px",
            md: "25px",
          },
          textAlign: "center",
          display: "block",
        }}
        component={Link}
        to={`/${store.slug}/${ORDERWEB_MODULES.MENULIST}`}
        variant="contained"
        color="success"
        disableElevation
      >
        Bestill fra vår meny
      </Button>
    );
  }
};

const Store = ({ currentLocation, store }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ margin: "15px 0px", width: "100%" }}>
          <Link to={`/${store.slug}`} style={{ textDecoration: "none" }}>
            <Typography
              variant="h6"
              component="h3"
              color="primary"
              sx={{ textDecoration: "none" }}
            >
              {store.title}
            </Typography>
          </Link>
          <Typography variant="p" component="p" sx={{ marginTop: "5px" }}>
            {store.address}
            <StoreDistance currentLocation={currentLocation} store={store} />
          </Typography>
          <Typography variant="p" component="p" sx={{ marginTop: "5px" }}>
            {store.phone && <>Tlf: {store.phone}</>}
          </Typography>
          <Button
            sx={{
              marginTop: "15px",
              display: {
                xs: "none",
                sm: "none",
                md: "inline-flex",
                lg: "inline-flex",
              },
            }}
            component={Link}
            to={`/${store.slug}/${ORDERWEB_MODULES.CONTACT_US}`}
            variant="outlined"
          >
            Veibeskrivelse og åpningstid
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ margin: "15px 0px", width: "100%", textAlign: "right" }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={3}
              md={4}
              lg={5}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <Button
                sx={{
                  marginTop: {
                    xs: "0px",
                    md: "30px",
                  },
                  marginRight: {
                    xs: "0px",
                    sm: "10px",
                    md: "0px",
                  },
                  marginLeft: {
                    xs: "0px",
                    md: "25px",
                  },
                  textAlign: "center",
                  display: "block",
                }}
                component={Link}
                to={`/${store.slug}/${ORDERWEB_MODULES.CONTACT_US}`}
                variant="outlined"
              >
                Veibeskrivelse og åpningstid
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={7}>
              <OrderButton store={store} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};

const StoreSelector = ({ autoredirect }) => {
  const dispatch = useDispatch();
  const currentLocation = useCurrentPosition();
  const activeUser = useSelector(selectActiveUserData);
  const [stores, setStores] = useState([]);
  const [sortedStores, setSortedStores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      autoredirect &&
      activeUser &&
      stores &&
      stores.length &&
      activeUser.favoritestore &&
      activeUser.favoritestore > 0
    ) {
      const foundStore = stores
        .filter((s) => s.mid === activeUser.favoritestore)
        .pop();

      if (foundStore && foundStore.slug) {
        navigate(`/${foundStore.slug}`);
      }
    }
  }, [autoredirect, navigate, stores, activeUser]);

  useEffect(() => {
    const loadData = async () => {
      const r = await SERVICES.fetchStores();
      const newStores = await r.json();
      setStores(newStores);
    };
    dispatch(setCurrentStoreId(0));
    loadData();
  }, [dispatch]);

  useEffect(() => {
    if (stores?.length && currentLocation?.valid) {
      const newStores = [...stores].sort(
        (a, b) =>
          DISTANCE_TOOLBOX.between(
            a.lat,
            a.lng,
            currentLocation.lat,
            currentLocation.lng
          ) -
          DISTANCE_TOOLBOX.between(
            b.lat,
            b.lng,
            currentLocation.lat,
            currentLocation.lng
          )
      );
      setSortedStores(newStores);
    } else {
      setSortedStores([...stores]);
    }
  }, [stores, currentLocation]);

  return (
    <OrderWebFrame>
      <Container component="main" sx={{ margin: "25px 0px" }}>
        <Box xs={12} mb={4}>
          <Typography variant="h4" component="h2">
            Våre stasjoner
          </Typography>
        </Box>
        {(sortedStores || []).map((store, idx) => (
          <Store
            currentLocation={currentLocation}
            key={`store_${idx}`}
            store={store}
          />
        ))}
      </Container>
    </OrderWebFrame>
  );
};

export default StoreSelector;
