import {
  FormControl,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { getBasketTotals, getEatIn } from "../../../redux/basketSlice";
import { selectStoreHasInPrice } from "../../../redux/storeDataSlice";
import { FORMATTER } from "../../../services";

const CheckoutStep5 = ({ step, control, availableForUse }) => {
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const totals = useSelector(getBasketTotals);
  const { sumTotal } = totals[eatIn ? "priceIn" : "priceOut"];
  const restToPay = sumTotal - availableForUse;

  if (availableForUse > 0) {
    return (
      <>
        <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
          {step}. Bruk bonus?
        </Typography>
        <Typography variant="p" component="div" sx={{ padding: "15px" }}>
          <p>
            Du kan bruke av din bonus for å redusere prisen på bestillingen.
          </p>
          <p>
            På denne bestillingen kan du redusere prisen med{" "}
            <b>kr. {FORMATTER.formatCurrent(availableForUse)}</b> ved bruk av
            bonus.
          </p>
          <p>
            Det gjenstår da <b>kr. {FORMATTER.formatCurrent(restToPay)}</b> å
            betale.
          </p>
        </Typography>
        <Controller
          name="useBonus"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={value} onChange={onChange} />}
                  label={`Jeg ønsker å bruke kr. ${FORMATTER.formatCurrent(
                    availableForUse
                  )} bonus nå`}
                />
              </FormGroup>
            </FormControl>
          )}
        />
      </>
    );
  } else {
    return (
      <>
        <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
          {step}. Bruk bonus?
        </Typography>
        <Typography variant="p" component="div" sx={{ padding: "15px" }}>
          <p>
            Du kan dessverre ikke bruke av din bonus for å redusere prisen på
            denne bestillingen.
          </p>
        </Typography>
      </>
    );
  }
};

export default CheckoutStep5;
