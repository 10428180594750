import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import { FORMATTER } from "../../services";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AddonsList = ({ addons, onChange, selectedAddons }) => {
  const [selected, setSelected] = useState(
    [...selectedAddons].map((e) => ({ ...e }))
  );
  const handleSelectionClick = (row) => {
    const wasSelected = isSelected(row);

    const newSelected = [...selected].filter(
      (item) => item && item.addid !== row.addid
    );

    if (!wasSelected) newSelected.push(row);
    setSelected(newSelected);
    if (onChange) onChange(newSelected);
  };

  const isSelected = (row) => {
    return (
      selected.filter(
        (item) => item && item.addid === row.addid && item.aoid === row.aoid
      ).length > 0
    );
  };

  const [expanded, setExpanded] = useState(false);

  const renderOption = (row, idx) => {
    const labelId = `checkbox-list-secondary-label_${idx}`;
    const title = `${row.option} (${FORMATTER.formatCurrent(row.price_out)})`;

    return (
      <ListItem
        key={labelId}
        disablePadding
        secondaryAction={
          <Checkbox
            edge="end"
            onChange={() => {
              handleSelectionClick(row);
              setExpanded(false);
            }}
            checked={isSelected(row)}
            inputProps={{ "aria-labelledby": labelId }}
          />
        }
      >
        <ListItemButton
          onClick={() => {
            handleSelectionClick(row);
            setExpanded(false);
          }}
        >
          <ListItemText id={labelId} primary={title} />
        </ListItemButton>
      </ListItem>
    );
  };

  const renderSelectedOptions = (rows) => {
    const selectedRows = (rows || []).filter((r) => isSelected(r));
    if (selectedRows && selectedRows.length) {
      const row = selectedRows.pop();
      return `${row.option} (${FORMATTER.formatCurrent(row.price_out)})`;
    }
    return "Legg til...";
  };

  const AddonsListOption = ({ row, isExpanded, setExpanded }) => {
    return (
      <Accordion expanded={isExpanded} onChange={setExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ display: "block" }}>
            {row[0].title}
            <Typography
              component="font"
              sx={{ display: "block", color: "text.secondary" }}
            >
              {row[0].disabled && (
                <Typography color="secondary">Midlertidig utsolgt</Typography>
              )}
              {!row[0].disabled && renderSelectedOptions(row)}
            </Typography>
          </Typography>
        </AccordionSummary>
        {!row[0].disabled && (
          <AccordionDetails
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <List disablePadding>
              {(row || []).map((r, i) => renderOption(r, i))}
            </List>
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  return (
    <>
      {(addons || []).map((row, idx) => {
        return (
          <AddonsListOption
            key={`addonlistrow_${idx}`}
            row={row}
            isExpanded={expanded === `addonlistrow_${idx}`}
            setExpanded={() =>
              setExpanded(
                expanded === `addonlistrow_${idx}`
                  ? false
                  : `addonlistrow_${idx}`
              )
            }
          />
        );
      })}
    </>
  );
};

export default AddonsList;
