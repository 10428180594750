import {
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Controller } from "react-hook-form";

const CheckoutStep4 = ({ step, control }) => {
  return (
    <>
      <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
        {step}. Spis inne eller ta med?
      </Typography>
      <Controller
        name="eatin"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl>
            <RadioGroup
              value={value}
              onChange={onChange}
              aria-labelledby="istimedelivery-label"
              name="istimedelivery"
            >
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Hent og ta med"
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Spis inne"
              />
            </RadioGroup>
          </FormControl>
        )}
      />
    </>
  );
};

export default CheckoutStep4;
