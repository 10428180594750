import { Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ORDERWEB_MODULES, PROGRAM_OWNER } from "../../constants";
import { selectStoreData } from "../../redux/storeDataSlice";
import { Link, useParams } from "react-router-dom";
import { StyledHeader } from "./header/HeaderStyles";
import HeaderMenu from "./header/HeaderMenu";

const menuStation = [
  {
    title: "Velg stasjon",
    page: () => `/velg-stasjon`,
  },
  {
    title: "Bestill fra meny",
    page: (storeKey) => `/${storeKey}/`,
  },
  {
    title: "Kontakt oss",
    page: (storeKey) => `/${storeKey}/${ORDERWEB_MODULES.CONTACT_US}`,
  },
];

const menuGeneric = [
  {
    title: "Velg stasjon",
    page: () => `/velg-stasjon`,
  },
];

const Header = () => {
  const storeKey = useParams().store;
  const storeData = useSelector(selectStoreData);
  const { title, phone, address } =
    storeData && storeData.store ? storeData.store : PROGRAM_OWNER;

  return (
    <>
      <StyledHeader
        sx={{
          position: {
            md: "fixed",
            lg: "fixed",
            xl: "fixed",
          },
          width: {
            md: "100vw",
            lg: "100vw",
            xl: "100vw",
          },
          zIndex: {
            md: "999",
            lg: "999",
            xl: "999",
          },
        }}
      >
        <div className="header-main-wrapper">
          <Container maxWidth="lg">
            <div className="header-main">
              <div className="logo-wrapper">
                <Link to={storeKey ? `/${storeKey}` : "/"}>
                  <img
                    src={PROGRAM_OWNER.logosmall}
                    alt="Best Logo"
                    aria-hidden="true"
                  />
                </Link>
              </div>
              <h1 className="store-title-one">{title}</h1>
              <Typography variant="p" component="p" className="store-title-2">
                {address}
                {phone && address && <> | </>}
                {phone && <>Tlf: {phone}</>}
              </Typography>
            </div>
          </Container>
        </div>
        <div className="menu-wrapper">
          <Container maxWidth="lg">
            <HeaderMenu
              menuStation={menuStation}
              menuGeneric={menuGeneric}
              color="white"
            />
          </Container>
        </div>
      </StyledHeader>
    </>
  );
};

export default Header;
