import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { SERVICES } from "../../services";
import {
  Paper,
  Button,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveToken,
  selectActiveUserData,
  setCustomerData,
} from "../../redux/activeSessionSlice";
import MyInfoForm from "./MyInfoForm";
import DeleteMyAccount from "./DeleteMyAccount";

const StyledForm = styled.form`
  .MuiFormControl-root {
    width: calc(100% - 50px);
    margin: 15px 25px 15px 25px;
  }
`;

const MyInfo = () => {
  const dispatch = useDispatch();
  const [loadingSave, setLoadingSave] = useState(false);
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState(false);
  const activeUser = useSelector(selectActiveUserData);
  const activeToken = useSelector(selectActiveToken);
  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      birthdate: "",
      favoritestore: 0,
      smsaccept: false,
    },
  });
  const formData = watch();

  const onSubmit = async () => {
    setLoadingSave(true);

    const { status, customer } = await SERVICES.saveUserProfile(
      activeToken,
      formData
    );

    if (status === "OK") {
      dispatch(setCustomerData(customer));
    } else if (status === "BAD_INPUT") {
      setError("Ugyldig skjema. Kontroller alle felter og prøv igjen.");
    } else if (status === "FORBIDDEN") {
      window.location = "/";
    } else {
      setError(
        "Det oppsto en feil med oppdatering av din profil. Prøv igjen senere."
      );
    }
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 2000);
    setLoadingSave(false);
  };

  useEffect(() => {
    if (activeUser) {
      setValue("name", activeUser.name);
      setValue("phone", activeUser.phone);
      setValue(
        "birthdate",
        activeUser.birthdate
          ? moment(activeUser.birthdate).format("DD.MM.YYYY")
          : ""
      );
      setValue(
        "favoritestore",
        activeUser.favoritestore ? activeUser.favoritestore : 0
      );
      setValue("smsaccept", activeUser.smsaccept);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser]);

  if (activeUser) {
    return (
      <>
        <Paper
          sx={{
            margin: "25px 0px 25px 0px",
            minHeight: "250px",
            padding: "10px",
          }}
          elevation={3}
        >
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <MyInfoForm control={control} />
            <Box
              sx={{
                height: error || saved ? "125px" : "36px",
                padding: "35px 25px",
              }}
            >
              {error ? (
                <Alert sx={{ marginBottom: "25px" }} severity="error">
                  <AlertTitle>Kan ikke oppdatere din profil</AlertTitle>
                  {error}
                </Alert>
              ) : (
                ""
              )}
              {saved && (
                <Alert sx={{ marginBottom: "25px" }} severity="success">
                  <AlertTitle>Profilen ble oppdatert</AlertTitle>
                  {error}
                </Alert>
              )}
              {loadingSave ? (
                <LinearProgress
                  sx={{
                    float: "right",
                    width: "220px",
                    height: "36px",
                    borderRadius: "4px",
                  }}
                />
              ) : (
                <Button
                  sx={{
                    float: {
                      xs: "none",
                      sm: "right",
                    },
                    width: {
                      xs: "100%",
                      sm: "220px",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disableElevation
                >
                  Lagre profil
                </Button>
              )}
            </Box>
          </StyledForm>
        </Paper>
        <Paper
          sx={{
            margin: "25px 0px 25px 0px",
            minHeight: "250px",
            padding: "10px",
          }}
          elevation={3}
        >
          <DeleteMyAccount />
        </Paper>
      </>
    );
  }
};

export default MyInfo;
