import Button from "@mui/material/Button";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect, useState } from "react";
import useMenyvelgerenAddons from "../../hooks/useMenyvelgerenAddons";
import PleaseWait from "../../views/PleaseWait";
import { COLORS, ENDPOINTS, ORDERWEB_MODULES } from "../../constants";
import {
  DialogContentText,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  Alert,
  Box,
  Tooltip,
  DialogTitle,
} from "@mui/material";
import AddonsTable from "./AddonsTable";
import AddonsList from "./AddonsList";
import {
  selectStoreData,
  selectStoreHasInPrice,
} from "../../redux/storeDataSlice";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToBasket } from "../../redux/basketSlice";
import { FORMATTER } from "../../services";
import QtyField from "../common/QtyField";

const StyledDialogTitle = styled.div`
  background: ${COLORS.PRIMARY};
  color: white;
  height: 130px;

  ${({ backgroundImage }) =>
    backgroundImage
      ? `
    background-color: rgba(0, 55, 112, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("${ENDPOINTS.image(backgroundImage)}");
    background-blend-mode: multiply;
    `
      : `
    background: ${COLORS.PRIMARY};
    border-top: 5px solid ${COLORS.SECONDARY};
  `}

  .dialog-title {
    line-height: 40px;
    font-size: 24px;
    padding: 65px 25px 25px 25px;

    @media only screen and (max-width: 899px) {
      padding: 30px 25px 25px 25px;
    }

    @media only screen and (max-width: 599px) {
      font-size: 20px;
      background: ${COLORS.PRIMARY};
      padding: 15px 25px 10px 25px;
    }
  }
`;

const StyledTotalContainer = styled.div`
  padding: 20px 10px 10px 30px;
`;

const StyledProductInfo = styled.div`
  background: #f3f4f4;
  padding: 25px;

  .product-title {
    font-weight: bold;
    line-height: 35px;
  }

  .product-price {
    text-align: right;
    padding-top: 10px;

    &:last-of-type {
      padding-right: 40px;
    }

    .price {
      font-size: 26px;
      display: block;
      color: ${COLORS.SECONDARY};
    }

    .description {
      display: block;
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;

const BootstrapDialogTitle = ({ onClose, picture }) => {
  return (
    <StyledDialogTitle backgroundImage={picture}>
      <div className="dialog-title">Tilpass din bestilling</div>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
};

const ProductInfo = ({ product, qty, addonsSum }) => {
  const hasInPrice = useSelector(selectStoreHasInPrice);

  return (
    <StyledProductInfo>
      <Grid container>
        <Grid item xs={12} md={8}>
          <div className="product-title">{product.title}</div>
          <div className="product-description">{product.description}</div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            marginTop: {
              xs: "15px",
              md: "0px",
            },
          }}
        >
          <Grid container>
            {hasInPrice && (
              <Grid item xs={6}>
                <div className="product-price price-in">
                  <span className="price">
                    {FORMATTER.formatCurrent(
                      [product.price_in, addonsSum.price_in],
                      qty
                    )}
                  </span>
                  <span className="description">Spis&nbsp;inne</span>
                </div>
              </Grid>
            )}
            <Grid item xs={hasInPrice ? 6 : 12}>
              <div className="product-price price-out">
                <span className="price">
                  {FORMATTER.formatCurrent(
                    [product.price_out, addonsSum.price_out],
                    qty
                  )}
                </span>
                {hasInPrice && <span className="description">Ta&nbsp;med</span>}
                {!hasInPrice && <span className="description">Pris</span>}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledProductInfo>
  );
};

const NotAvailableButton = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Midlertidig utsolgt">
        <IconButton
          aria-label="Midlertidig utsolgt"
          color="secondary"
          onClick={handleClickOpen}
        >
          <DoNotDisturbIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Midlertidig utsolgt</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Produktet er midlertidig utsolgt. Velg gjerne noe annet fra menyen
            eller prøv igjen senere.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus variant="outlined">
            OK, tilbake til meny
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddProductToCartDialog = ({ product }) => {
  const dispatch = useDispatch();
  const storeKey = useParams().store;
  const [qty, setQty] = useState(1);
  const [comment, setComment] = useState("");
  const storeData = useSelector(selectStoreData);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [navigateDialogOpen, setNavigateDialogOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningType, setShowWarningType] = useState("");
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addonsSum, setAddonsSum] = useState({
    price_in: 0,
    price_out: 0,
  });
  const [addonsData, loading] = useMenyvelgerenAddons(
    storeData && storeData.store && storeData.store.mid,
    product && product.pid ? product.pid : 0,
    !dialogOpen
  );
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleAddProductToBasket = () => {
    const { product } = addonsData;
    setDialogOpen(false);

    dispatch(
      addToBasket({
        product: product,
        addons: selectedAddons,
        qty: qty,
        visibleComment: comment && comment.length > 0,
        comment: comment,
      })
    );

    setNavigateDialogOpen(true);
  };

  const handleConfirmWarning = (accept) => {
    setShowWarning(0);
    if (accept) {
      setDialogOpen(true);
    }
  };

  const handleAddonSelectionChange = (newSelectedAddons) => {
    const sumAddonsIn = (newSelectedAddons || [])
      .map((e) => parseFloat(e.price_in))
      .filter((e) => e)
      .reduce((sum, a) => sum + a, 0);
    const sumAddonsOut = (newSelectedAddons || [])
      .map((e) => parseFloat(e.price_out))
      .filter((e) => e)
      .reduce((sum, a) => sum + a, 0);

    setAddonsSum({
      price_in: sumAddonsIn,
      price_out: sumAddonsOut,
    });
    setSelectedAddons(newSelectedAddons);
  };

  useEffect(() => {
    if (dialogOpen) {
      setSelectedAddons([]);
      setAddonsSum({
        price_in: 0,
        price_out: 0,
      });
    }
  }, [dialogOpen, product]);

  if (product.disabled === "1") {
    return <NotAvailableButton />;
  }

  return (
    <>
      <IconButton
        aria-label="Legg til i handlekurv"
        color="primary"
        onClick={() => {
          if (product.tobacco_cigarettes === "1") {
            setShowWarning(1);
            setShowWarningType("tobacco_cigarettes");
          } else if (product.tobacco_snuff === "1") {
            setShowWarning(1);
            setShowWarningType("tobacco_snuff");
          } else {
            setDialogOpen(true);
          }
        }}
      >
        <AddCircleIcon />
      </IconButton>

      <Dialog
        open={showWarning > 0}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {showWarningType === "tobacco_snuff" && (
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                color: "black",
                fontSize: "18px",
                textAlign: "center",
                padding: "15px",
              }}
            >
              Denne tobakksvaren kan være helseskadelig og er
              avhengighetsskapende. Ikke for personer under 18 år.
            </DialogContentText>
          )}
          {showWarningType === "tobacco_cigarettes" && (
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                color: "black",
                fontSize: "18px",
                textAlign: "center",
                padding: "15px",
              }}
            >
              Røyking er svært skadelig for deg og dine omgivelser. Ikke for
              personer under 18 år.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ padding: "10px 35px" }}
            onClick={() => handleConfirmWarning(false)}
          >
            Avbryt
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            variant="contained"
            disableElevation
            sx={{ padding: "10px 35px" }}
            onClick={() => handleConfirmWarning(true)}
            autoFocus
          >
            Fortsett &raquo;
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setDialogOpen(false)}
        fullWidth={true}
        maxWidth="lg"
        fullScreen={isXsScreen}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setDialogOpen(false)}
          picture={product.picture}
        />
        <ProductInfo product={product} addonsSum={addonsSum} qty={qty} />
        <DialogContent>
          {loading && <PleaseWait />}
          {!loading && !isXsScreen && (
            <AddonsTable
              onChange={handleAddonSelectionChange}
              selectedAddons={selectedAddons}
              addons={(addonsData || {}).addons}
            />
          )}
          {!loading && isXsScreen && (
            <AddonsList
              onChange={handleAddonSelectionChange}
              selectedAddons={selectedAddons}
              addons={(addonsData || {}).addons}
            />
          )}
        </DialogContent>
        <DialogActions>
          <TextField
            id="outlined-multiline-static"
            label="Kommentar til bestillingen"
            multiline
            rows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            sx={{
              width: "100%",
              maxWidth: "500px",
            }}
          />
          <div style={{ flex: "1 0 0" }} />
          <QtyField
            value={qty}
            onChange={(qty) => setQty(qty)}
            sx={{ lineHeight: "35px" }}
          />
          <StyledTotalContainer>
            <Button
              onClick={handleAddProductToBasket}
              disableElevation
              color="success"
              variant="contained"
            >
              Legg til i handlekurv
            </Button>
          </StyledTotalContainer>
        </DialogActions>
      </Dialog>

      <Dialog open={navigateDialogOpen} fullWidth={true} maxWidth="md">
        <DialogContent>
          <Box sx={{ m: 2 }}>
            <Alert severity="success">
              Produktet ble lagt til i handlekurven.
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  padding: "10px 35px",
                  display: {
                    xs: "block",
                    sm: "inline-flex",
                  },
                }}
                component={Link}
                to={`/${storeKey}/${ORDERWEB_MODULES.MENULIST}`}
              >
                Handle mer
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                textAlign: {
                  xs: "left",
                  sm: "right",
                },
              }}
            >
              <Button
                variant="contained"
                disableElevation
                sx={{
                  padding: "10px 35px",
                  display: {
                    xs: "block",
                    sm: "inline-flex",
                  },
                  marginTop: {
                    xs: "15px",
                    sm: 0,
                  },
                }}
                component={Link}
                to={`/${storeKey}/${ORDERWEB_MODULES.CHECKOUT}`}
                autoFocus
                color="success"
              >
                Gå videre til betaling &raquo;
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddProductToCartDialog;
