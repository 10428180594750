import { Grid, Paper } from "@mui/material";
import OrderWebFrame from "../components/common/OrderWebFrame";
import GenericTextContent from "../components/common/GenericTextContent";

const GenericTextPage = ({ text }) => {
  return (
    <OrderWebFrame>
      <Paper sx={{ margin: " 25px", padding: "15px" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <GenericTextContent text={text} noStoreId={true} />
          </Grid>
        </Grid>
      </Paper>
    </OrderWebFrame>
  );
};

export default GenericTextPage;
