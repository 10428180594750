import { ReactComponent as BurgerIcon } from "./assets/food-icon/burger.svg";
import { ReactComponent as PizzaIcon } from "./assets/food-icon/pizza.svg";
import { ReactComponent as PizzaekspertenIcon } from "./assets/food-icon/pizzaeksperten.svg";
import { ReactComponent as SausageSvg } from "./assets/food-icon/sausage.svg";
import { ReactComponent as HotFoodIcon } from "./assets/food-icon/hotfood.svg";
import { ReactComponent as OtherIcon } from "./assets/food-icon/other.svg";
import { ReactComponent as SaladIcon } from "./assets/food-icon/salad.svg";
import { ReactComponent as DrinkIcon } from "./assets/food-icon/drink.svg";
import { ReactComponent as KioskIcon } from "./assets/food-icon/kiosk.svg";
import { ReactComponent as TobaccoIcon } from "./assets/food-icon/tobacco.svg";
import { ReactComponent as CarIcon } from "./assets/food-icon/car.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";

export const VAT = {
  EATIN: 25,
  TAKEOUT: 15,
  NO_FOOD: 25,
  FOOD_GROCERIES: 15,
};

export const PROGRAM_OWNER = {
  title: window.ToppBonusConfig.program.title,
  slogan: window.ToppBonusConfig.program.slogan,
  phone: window.ToppBonusConfig.program.phone,
  email: window.ToppBonusConfig.program.email,
  logofull: window.ToppBonusConfig.program.logofull,
  logosmall: window.ToppBonusConfig.program.logosmall,
};

export const ENDPOINTS = {
  base: () => window.ToppBonusConfig.endpoints.api,
  image: (id) => `${window.ToppBonusConfig.endpoints.image}${id}`,
  paymentBase: () => window.ToppBonusConfig.endpoints.payment,
  payment: (paymentProviderId, orderId) =>
    `${ENDPOINTS.paymentBase()}payment/makepayment/${paymentProviderId}/${orderId}`,
};

export const COLORS = {
  PRIMARY: window.ToppBonusConfig.profilecolor || "#003770",
  SECONDARY: window.ToppBonusConfig.accentcolor || "#ef7d00",
};

export const REDUX_DATA_STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  NOT_FOUND: "NOT_FOUND",
};

export const ORDERWEB_MODULES = {
  MENULIST: `meny`,
  PRODUCTLIST: `produkter`,
  BASKET: "handlekurv",
  CHECKOUT: "kasse",
  CONFIRM: "din-bestilling",
  SALESTERMS: "salgsbetingelser",
  DELETEME: "slett-profil",
  ABOUTPROGRAM: "om-bestbonus",
  CONTACT_US: "kontakt-oss",
};

export const PROFILE_MODULES = {
  ROOT: "minside",
  BONUS: "bonus",
  ORDERS: "bestillinger",
  PROFILE: "profil",
};

export const createProfileLink = (storeKey, profile) =>
  "/" + [storeKey, PROFILE_MODULES.ROOT, profile].filter((e) => e).join("/");

export const MENYVELGER_MENUGROUP = {
  JULEKALENDER: {
    title: "Julekalender",
    sortkey: 10,
    image: "/images/food-icon/julekalender.png",
    useImage: true,
  },
  PROMOTION: {
    title: "Kampanje",
    sortkey: 11,
    icon: <StarBorderIcon />,
  },
  PIZZAEKSPERTEN: {
    title: "Pizzaeksperten",
    sortkey: 20,
    icon: <PizzaekspertenIcon />,
  },
  PIZZA: {
    title: "Pizza",
    sortkey: 30,
    icon: <PizzaIcon />,
  },
  BURGER: {
    title: "Burger",
    sortkey: 40,
    icon: <BurgerIcon />,
  },
  SAUSAGE: {
    title: "Pølse",
    sortkey: 50,
    icon: <SausageSvg />,
  },
  SALAD: {
    title: "Salater, påsmurt og panini",
    sortkey: 70,
    icon: <SaladIcon />,
  },
  OTHER: {
    title: "Andre retter",
    sortkey: 80,
    icon: <OtherIcon />,
  },
  DRINK: {
    title: "Drikke",
    sortkey: 90,
    icon: <DrinkIcon />,
  },
  KIOSK: {
    title: "Kiosk og dagligvare",
    sortkey: 100,
    icon: <KioskIcon />,
  },
  TOBACCO: {
    title: "Tobakk",
    sortkey: 110,
    icon: <TobaccoIcon />,
  },
  CAR: {
    title: "Bil, hjem og fritid",
    sortkey: 120,
    icon: <CarIcon />,
  },
  TUNA: {
    title: "Halal Tuna",
    sortkey: 130,
    useImage: true,
    image: "/images/food-icon/halaltuna.png",
  },

  // TO BE DELETED
  HOTFOOD: {
    title: "Varmmat",
    sortkey: 60,
    icon: <HotFoodIcon />,
  },
};
