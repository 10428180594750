import React, { useEffect, useState } from "react";
import {
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Typography,
  FormControlLabel,
  FormHelperText,
  Switch,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import { Controller } from "react-hook-form";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { SERVICES } from "../../services";
import { COLORS } from "../../constants";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const MyInfoForm = ({ control }) => {
  const [stores, setStores] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const r = await SERVICES.fetchStores();
      const newStores = await r.json();
      setStores(newStores);
    };
    loadData();
  }, []);

  return (
    <>
      <Avatar
        sx={{
          bgcolor: COLORS.PRIMARY,
          float: "left",
          margin: {
            xs: "10px 5px 10px 0px",
            sm: "10px 15px",
          },
          transform: {
            xs: "scale(0.7)",
            sm: "scale(1)",
          },
        }}
      >
        <AccountCircleIcon />
      </Avatar>
      <Typography variant="h5" component="h2" sx={{ padding: "15px" }}>
        Din profil
      </Typography>
      <Controller
        name="name"
        control={control}
        rules={{
          required: {
            value: true,
            message: "Navn er påkrevd",
          },
          minLength: {
            value: 3,
            message: "Navn er påkrevd",
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} variant="standard">
            <InputLabel htmlFor="input-name">Navn</InputLabel>
            <Input
              id="input-name"
              label="Navn"
              sx={{ width: "100%" }}
              value={value}
              onChange={onChange}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              }
            />
            <FormHelperText>{error ? error.message : null}</FormHelperText>
          </FormControl>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} variant="standard">
            <InputLabel htmlFor="input-phone">Mobilnummer</InputLabel>
            <Input
              id="input-phone"
              label="Mobilnummer"
              disabled
              value={value}
              onChange={onChange}
              startAdornment={
                <InputAdornment position="start">
                  <PhoneAndroidIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        )}
      />
      <Controller
        name="birthdate"
        control={control}
        rules={{
          pattern: {
            value: /^([0-9]{2}.[0-9]{2}.[0-9]{4})$/,
            message:
              "Fødselsdato må være på format dd.mm.yyyy, f.eks. 20.10.1999",
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} variant="standard">
            <InputLabel htmlFor="birthday-input">
              Fødselsdato (dd.mm.yyyy)
            </InputLabel>
            <Input
              id="birthday-input"
              label="Fødselsdato (dd.mm.yyyy)"
              sx={{ width: "100%" }}
              value={value}
              onChange={onChange}
              startAdornment={
                <InputAdornment position="start">
                  <CardGiftcardIcon />
                </InputAdornment>
              }
            />
            <FormHelperText>{error ? error.message : null}</FormHelperText>
          </FormControl>
        )}
      />
      <Controller
        name="favoritestore"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} variant="standard">
            <InputLabel htmlFor="favoritestore-input">
              Favorittstasjon
            </InputLabel>
            <Select
              id="favoritestore-input"
              value={value}
              onChange={onChange}
              label="Favorittstasjon"
              sx={{ width: "100%" }}
              startAdornment={
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              }
            >
              <MenuItem value={0}>
                <em>Ingen</em>
              </MenuItem>

              {(stores || []).map((store) => (
                <MenuItem key={`store_${store.mid}`} value={store.mid}>
                  {store.title}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText>{error ? error.message : null}</FormHelperText>
          </FormControl>
        )}
      />
      <Controller
        name="smsaccept"
        control={control}
        render={({
          field: { onChange, value, field },
          fieldState: { error },
        }) => (
          <FormControlLabel
            sx={{ margin: "10px 15px 5px 15px" }}
            control={<Switch checked={value} onChange={onChange} />}
            label="Send meg tilbud på SMS"
          />
        )}
      />
    </>
  );
};

export default MyInfoForm;
